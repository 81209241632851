import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { PopupContext } from '../LoginPopupContext';

export default function TestList() {
    const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

    const initialized = useRef(false);
    const [apiData, setApiData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            listAPI();
        }
    }, []);

    const listAPI = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getAllTest`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                if (response.status === 200) {
                    let paresData = await response.json();
                    setApiData(paresData);
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setLoadingStop();
                console.log(error);
            });
    }

    const handleView = (item) => {
        if (item.type && item.type === 'advance') {
            navigate(`/testViewIndicator/${item._id}`)
        } else {
            navigate(`/testView/${item._id}`)
        }
    }

    return (
        <div className="quiz-box">
            <div className="title">Quiz List</div>
            <div className="box-wraper">
                <div className="box">
                    <table id="datatablesSimple" className="datatable-table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Quiz Name</th>
                                <th>Quiz Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {apiData && apiData.tets.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.titleName}</td>
                                    <td>{item.type && item.type === 'advance' ? 'Indicator Test' : 'Mark Test'}</td>
                                    <td><span className='btn btn-primary btn-sm' onClick={() => handleView(item)}>View</span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
