import { useContext, useEffect } from "react";
import AdminDashboard from "./component/admin/AdminDashboard";
import LoginPage from "./component/LoginPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import TestDashboard from "./component/testManager/TestDashboard";
import TestView from "./component/testManager/TestView";
import StudentDashboard from "./component/student/StudentDashboard";
import StartTest from "./component/student/StartTest";
import StudentTestView from "./component/student/StudentTestView";
import { PopupContext } from "./component/LoginPopupContext";
import Loader from "./component/Loader";
import AdminTestView from "./component/admin/AdminTestView";
import AdminTestEditView from "./component/admin/AdminTestEditView";
import TestViewIndicator from "./component/testManager/TestViewIndicator";
import AdminMbtiTestView from "./component/admin/AdminMbtiTestView";
import AdminMBTITestEditView from "./component/admin/AdminMBTITestEditView";
import Footer from "./component/admin/Footer";


function RedirectToAdminLogin() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);

  return null;
}

function App() {
  const { isLogedIn, isloading } = useContext(PopupContext);

  const preventBackNavigation = () => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", () => {
      window.history.pushState(null, "", window.location.href);
    });
  };

  useEffect(() => {
    // preventBackNavigation();

    // // Clean up on component unmount
    // return () => {
    //   window.removeEventListener("popstate", () => {
    //     window.history.pushState(null, "", window.location.href);
    //   });
    // };
  }, [isLogedIn]);

  return (
    <>
      {isloading ? <Loader /> : ''}
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/adminDashboard" element={sessionStorage.getItem("token") || isLogedIn ? <AdminDashboard /> : <Navigate to="/login" />} />
        <Route path="/testDashboard" element={sessionStorage.getItem("token") || isLogedIn ? <TestDashboard /> : <Navigate to="/login" />} />
        <Route path="/studentDashboard" element={sessionStorage.getItem("token") || isLogedIn ? <StudentDashboard /> : <Navigate to="/login" />} />
        <Route path="/testView/:id" element={sessionStorage.getItem("token") || isLogedIn ? <TestView /> : <Navigate to="/login" />} />
        <Route path="/testViewIndicator/:id" element={sessionStorage.getItem("token") || isLogedIn ? <TestViewIndicator /> : <Navigate to="/login" />} />
        <Route path="/quizStarted/:id" element={sessionStorage.getItem("token") || isLogedIn ? <StartTest /> : <Navigate to="/login" />} />
        <Route path="/quizViewResult/:id" element={sessionStorage.getItem("token") || isLogedIn ? <StudentTestView /> : <Navigate to="/login" />} />
        <Route path="/adminTstView/:id/:stdid" element={sessionStorage.getItem("token") || isLogedIn ? <AdminTestView /> : <Navigate to="/login" />} />
        <Route path="/adminMBTITstView/:id/:stdid" element={sessionStorage.getItem("token") || isLogedIn ? <AdminMbtiTestView /> : <Navigate to="/login" />} />
        <Route path="/adminTestEditView/:id" element={sessionStorage.getItem("token") || isLogedIn ? <AdminTestEditView /> : <Navigate to="/login" />} />
        <Route path="/adminMBTITestEditView/:id" element={sessionStorage.getItem("token") || isLogedIn ? <AdminMBTITestEditView /> : <Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
}

export default App;
