import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { PopupContext } from '../LoginPopupContext';

export default function AdminTestList() {
    const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

    const initialized = useRef(false);
    const [apiData, setApiData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            listAPI();
        }
    }, []);

    const listAPI = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getAllTest`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                if (response.status === 200) {
                    let paresData = await response.json();
                    setApiData(paresData);
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setLoadingStop();
                console.log(error);
            });
    }

    const handleView = (item) => {
        if(item.type === "advance"){
            navigate(`/adminMBTITestEditView/${item._id}`)
        }else{
            navigate(`/adminTestEditView/${item._id}`)
        }
    }

    return (
        <div className="quiz-box">
            <div className="title">Quiz List</div>
            <div className="box-wraper">
                {apiData && apiData.tets.map((item, index) => (
                    <div className="box">
                        <div className="w-100 row">
                            <label className="form-label">Quiz Title:</label>
                            <div className="col-sm-11">
                                <label className="w-100 form-label" htmlFor={item.titleName}>{item.titleName}</label>
                                <span className='btn btn-primary btn-sm' onClick={() => handleView(item)}>View</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
