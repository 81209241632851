import React, { useContext, useEffect, useRef, useState } from 'react'
import MyComponent from '../testManager/MyComponent';
import { useNavigate } from 'react-router-dom';
import { PopupContext } from '../LoginPopupContext';

export default function CompleteQuiz() {
    const initialized = useRef(false);
    const [apiDATA, setApiData] = useState();
    const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);
    const [msg, setMsg] = useState("");
    const [isSuccess, setIsSucess] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callCompleteTest();
        }
    }, []);

    const callCompleteTest = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
            "studentId": sessionStorage.getItem("userId"),
            "testStatus": "Completed"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/showTest`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                let paresData = await response.json();
                if (response.status === 200) {
                    setApiData(paresData);
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setLoadingStop();
                setErrors(error);
                console.log(error);
            });
    }

    const handleViewmore = (item) => {
        nav(`/quizViewResult/${item.testId._id}`)
    }

    const setErrors = (error) => {
        setIsError(true);
        setError(error);
        setTimeout(() => {
            setIsError(false);
            setError("");
        }, 2000);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;

        const formattedTime = `${String(hours).padStart(
            2,
            "0"
        )}:${minutes} ${ampm}`;

        return `${day}/${month}/${year} ${formattedTime}`;
    };

    return (
        <div>
            <div style={{ marginTop: "15px" }}>
                {isError ? <div
                    className={`alert alert-danger fade ${isError ? "show" : " "}`}
                    role="alert"
                >
                    <strong>{error}</strong>
                </div> : ''}

                {isSuccess ? <div
                    className={`alert alert-success fade ${isSuccess ? "show" : " "}`}
                    role="alert"
                >
                    <strong>{msg}</strong>
                </div> : ''}
            </div>
            <div className="quiz-box">
                <div className="title">Completed Quiz</div>
                <div className="box-wraper">
                    <table id="datatablesSimple" className="datatable-table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Quiz Name</th>
                                <th>Allocated Time</th>
                                <th>Eaxm Start Time</th>
                                <th>Exam Completed Time</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                apiDATA &&
                                    apiDATA.tests && apiDATA.tests.length > 0 ? (
                                    apiDATA.tests.map((item, index) => (

                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.testId.titleName}</td>
                                            <td>{formatDate(item.allocateTime)}</td>
                                            <td>{formatDate(item.startTime)}</td>
                                            <td>{formatDate(item.endTime)}</td>
                                        </tr>

                                    ))) : (
                                    <div>
                                        Quiz not found!!!
++                                    </div>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
