import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PopupContext } from "../LoginPopupContext";

export default function SideNav() {
  const { selectedMenu, setCurrentMenu,clearMenu } = useContext(PopupContext);

  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <div className={`nav-link ${selectedMenu === "Home" ? 'active' : ''}`} onClick={()=>setCurrentMenu("Home")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Home
            </div>

            {/* <div className={`nav-link ${selectedMenu === "Report" ? 'active' : ''}`} onClick={()=>setCurrentMenu("Report")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Report
            </div> */}

            <div className={`nav-link ${selectedMenu === "createStd" ? 'active' : ''}`} onClick={()=>setCurrentMenu("createStd")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Create Student
            </div>

            <div className={`nav-link ${selectedMenu === "stdList" ? 'active' : ''}`} onClick={()=>setCurrentMenu("stdList")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Student List
            </div>

            <div className={`nav-link ${selectedMenu === "testLogin" ? 'active' : ''}`} onClick={()=>setCurrentMenu("testLogin")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Quiz Login Manage
            </div>
            <div className={`nav-link ${selectedMenu === "testAllocate" ? 'active' : ''}`} onClick={()=>setCurrentMenu("testAllocate")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Quiz Allocation
            </div>
            <div className={`nav-link ${selectedMenu === "removeTestAllocate" ? 'active' : ''}`} onClick={()=>setCurrentMenu("removeTestAllocate")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Allocated quiz remove
            </div>
            <div className={`nav-link ${selectedMenu === "AllocatedStudents" ? 'active' : ''}`} onClick={()=>setCurrentMenu("AllocatedStudents")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Allocated Students
            </div>
            <div className={`nav-link ${selectedMenu === "EditQuiz" ? 'active' : ''}`} onClick={()=>setCurrentMenu("EditQuiz")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Edit Quiz
            </div>
            <div className={`nav-link ${selectedMenu === "ResetQuiz" ? 'active' : ''}`} onClick={()=>setCurrentMenu("ResetQuiz")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Reset Quiz
            </div>
            <div className={`nav-link ${selectedMenu === "DeleteStudents" ? 'active' : ''}`} onClick={()=>setCurrentMenu("DeleteStudents")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Delete Students
            </div>
          </div>
        </div>
        {/* <div className="sb-sidenav-footer">
          <div className="small">Logged in as:</div>
          Start Bootstrap
        </div> */}
      </nav>
    </div>
  );
}
