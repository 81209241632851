import React from 'react';
import DOMPurify from 'dompurify';

function MyComponent({ apiData }) {
    const sanitizedData = DOMPurify.sanitize(apiData);

    return (
        <div
            dangerouslySetInnerHTML={{ __html: sanitizedData }}
        />
    );
}

export default MyComponent;
