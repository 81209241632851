import React, { useContext, useEffect, useState } from 'react'
import { modules, formats, noImageformats, noImagemodules } from './Utils';
import ReactQuill from 'react-quill';
import { PopupContext } from '../LoginPopupContext';
import { Button } from 'react-bootstrap';

export default function TestIndicatorCreate() {
    const { setLoadingStart, setLoadingStop, setUnauthorized } = useContext(PopupContext);

    const [totalQue, setTotalQue] = useState(1);
    const [totalEx, setTotalEx] = useState(1);
    const [options, setOptions] = useState([]);

    const [totalQueShow, setTotalQueShow] = useState('');
    const [instruction, setInstruction] = useState('');
    const [examTime, setExamTime] = useState();


    const [time, setTime] = useState('');
    const [queTitle, setQueTitle] = useState("");
    const [queList, setQueList] = useState([]);

    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);
    const [msg, setMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const [exList, setExList] = useState([]);

    const [question, setQuestion] = useState([]);

    const handleAddExample = () => {
        setTotalEx(prevTotalEx => prevTotalEx + 1);
    }

    const handleSubExample = () => {
        setTotalEx(prevTotalEx => prevTotalEx - 1);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // for a smooth scrolling effect
        });
    };

    useEffect(() => {
        setQuestion(Array(totalQue).fill(''));
        setExList(Array(totalEx).fill(''));
        setOptions(Array.from({ length: totalQue }, () => [{ id: 0, title: "", indicator: "" }, { id: 1, title: "", indicator: "" },]));
    }, [totalQue, totalEx]);

    const handleTotalQue = (event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            setTotalQue(inputValue);
        }
    };

    const handleExamTime = (event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            setExamTime(inputValue);
        }
    };

    const handleTime = (value) => {
        setTime(value);
    };
    const handleInstruction = (value) => {
        setInstruction(value);
    };

    const handleTitle = (event) => {
        setQueTitle(event.target.value);
    };

    const resetData = () => {
        setTotalQue(1);
        setTime('');
        setQueTitle("");
        setQueList([]);

        setError("");
        setIsError(false);

        setQuestion([]);
        setOptions([]);
    }

    const createTest = (data) => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/createTest`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                scrollToTop();
                if (response.status === 200) {
                    let paresData = await response.json();
                    setSuccess("Test created successfully.");
                    resetData();
                } else if (response.status === 401) {
                    setUnauthorized();
                }
            })
            .catch((error) => {
                setLoadingStop();
                setErrors(error);
                console.log(error);
            });
    }

    const setErrors = (error) => {
        setIsError(true);
        setError(error);
        setTimeout(() => {
            setIsError(false);
            setError("");
        }, 2000);
    };

    const setSuccess = (msg) => {
        setIsSuccess(true);
        setMsg(msg);
        setTimeout(() => {
            setIsSuccess(false);
            setMsg("");
        }, 2000);
    };

    const handleClick = () => {
        const tempQueList = [];
        for (let index = 0; index < totalQue; index++) {
            const allOpt = options[index]
            const que = question[index];

            const finalQue = {
                id: index,
                question: que,
                option: allOpt
            }
            tempQueList.push(finalQue);
        }
        setQueList(tempQueList);

        const data = {
            titleName: queTitle,
            instruction: instruction,
            quizDuration: examTime,
            type: "advance",
            example: exList,
            quizTime: time,
            totalQue: totalQueShow,
            question: tempQueList
        }

        createTest(data);
    }

    const handleExChange = (index) => (value) => {
        const newValues = [...exList];
        newValues[index] = value; // Update the value at the specific index
        setExList(newValues);
    };

    const handleQueChange = (index) => (value) => {
        const newValues = [...question];
        newValues[index] = value; // Update the value at the specific index
        setQuestion(newValues);
    };

    const handleTotalShowQue = (value) => {
        setTotalQueShow(value)
    }

    const handleAddOption = (questionIndex) => {
        setOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[questionIndex] = [
                ...(updatedOptions[questionIndex] || []),
                { id: (updatedOptions[questionIndex]?.length || 0), title: '' }
            ];
            return updatedOptions;
        });
    };

    const handleRemoveOption = (questionIndex, optionIndex) => {
        setOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[questionIndex] = updatedOptions[questionIndex].filter((_, idx) => idx !== optionIndex);
            return updatedOptions;
        });
    };

    const handleOptionChange = (questionIndex, optionIndex, value) => {
        setOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[questionIndex][optionIndex] = { ...updatedOptions[questionIndex][optionIndex], title: value };
            return updatedOptions;
        });
    };

    const handleIndicatorOptionChange = (questionIndex, optionIndex, value) => {
        setOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[questionIndex][optionIndex] = { ...updatedOptions[questionIndex][optionIndex], indicator: value };
            return updatedOptions;
        });
    };

    return (
        <>
            {isError ? <div
                className={`alert alert-danger fade ${isError ? "show" : " "}`}
                role="alert"
            >
                <strong>{error}</strong>
            </div> : ''}

            {isSuccess ? <div
                className={`alert alert-success fade ${isSuccess ? "show" : " "}`}
                role="alert"
            >
                <strong>{msg}</strong>
            </div> : ''}

            <div className="quiz-box">
                <div className="title">Quiz Information</div>
                <div className="box-wraper">
                    <div className="box">
                        <div className="mb-3 row">
                            <label className="col-sm-1 form-label">Quiz Title</label>
                            <div className="col-sm-11">
                                <input type='text' className="form-control" value={queTitle} onChange={handleTitle} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-1 form-label">Total Questions in Number</label>
                            <div className="col-sm-11">
                                <input type='text' className="form-control" value={totalQue} onChange={handleTotalQue} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-1 form-label">Total Exam Time in Minute</label>
                            <div className="col-sm-11">
                                <input type='text' className="form-control" value={examTime} onChange={handleExamTime} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-1 form-label">Total Questions in Word</label>
                            <div className="col-sm-11">
                                <ReactQuill
                                    value={totalQueShow}
                                    modules={noImagemodules}
                                    formats={noImageformats}
                                    onChange={handleTotalShowQue}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-1 form-label">Quiz Time</label>
                            <div className="col-sm-11">
                                <ReactQuill
                                    value={time}
                                    modules={noImagemodules}
                                    formats={noImageformats}
                                    onChange={handleTime}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="quiz-box">
                <div className="title">Add Instruction</div>
                <div className="box-wraper">
                    <div className="box">
                        <div className="w-100">
                            <div className="w-100">
                                <ReactQuill
                                    value={instruction}
                                    modules={noImagemodules}
                                    formats={noImageformats}
                                    onChange={handleInstruction}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="quiz-box">
                <div className="title">Add Example</div>
                <div className="box-wraper">
                    {Array.from({ length: totalEx }, (_, index) => (
                        <div key={index} className="box">
                            <div className="w-100">
                                <div className="w-100">
                                    {1 < totalEx ? <span className='remove-btn' onClick={handleSubExample}><i class="fa-solid fa-trash-can" style={{ color: "#ff0000" }}></i></span> : ''}

                                    <ReactQuill
                                        value={exList[index] || ''}
                                        modules={modules}
                                        formats={formats}
                                        onChange={handleExChange(index)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='box'>
                        <span className="btn btn-primary" onClick={handleAddExample}>+ Add</span>
                    </div>
                </div>
            </div>

            <div className="quiz-box">
                <div className="title">Questions</div>
                <div className="box-wraper">
                    {Array.from({ length: totalQue }, (_, index) => (
                        <div className="box" key={index} id={`${index}`}>
                            <h2 className="q-no">Question {index + 1}</h2>
                            <div className="mb-3 row">
                                <label className="col-sm-1 form-label">Question</label>
                                <div className="col-sm">
                                    <ReactQuill
                                        value={question[index] || ''}
                                        modules={modules}
                                        formats={formats}
                                        onChange={handleQueChange(index)}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-1 form-label align-items-start">Options</label>
                                <div className="col-sm-5">

                                    {options[index]?.map((opt, optIndex) => (
                                        <div key={optIndex}  style={{display: "flex", alignItems: "center", marginBottom: "5px"}}>
                                            <textarea
                                                value={opt.title}
                                                className="form-controlll"
                                                placeholder={`Option ${optIndex + 1}`}
                                                onChange={(e) => handleOptionChange(index, optIndex, e.target.value)}
                                                style={{ lineHeight: "2" }}  // This sets the line height to be 2x the normal height
                                            />
                                            <input
                                                type="text"
                                                value={opt.indicator}
                                                className='form-controlll'
                                                style={{ marginLeft: "10px" }}
                                                placeholder={`Indicator ${optIndex + 1}`}
                                                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                                onChange={(e) => handleIndicatorOptionChange(index, optIndex, e.target.value)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="box">
                        <div className="row">
                            <div className="col-sm-12 btn-sec">
                                <button className="btn btn-success" onClick={() => handleClick()} >Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
