import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import MyComponent from '../testManager/MyComponent';
import TestNav from './TestNav';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { PopupContext } from '../LoginPopupContext';
import CompletedQuizDialog from './CompletedQuizDialog';
import StartedQuizDialog from './StartedQuizDialog';

export default function StartTest() {
    const { id } = useParams();
    const initialized = useRef(false);
    const [apiData, setApiData] = useState();
    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);
    const [isTestStart, setIsTestStart] = useState(false);
    const [isTestStarted, setIsTestStarted] = useState(true);
    const [isConfirmation, setIsConfirmation] = useState(false);

    const [msg, setMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [time, setTime] = useState(15 * 60); // 15 minutes in seconds
    const buttonRef = useRef(null);
    const { setLoadingStart, setLoadingStop, setUnauthorized } = useContext(PopupContext);

    const nav = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // for a smooth scrolling effect
        });
    };

    // State to track selected answers for each question
    const [selectedOptions, setSelectedOptions] = useState({});

    const handleOptionChange = (questionIndex, optionIndex, t) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [questionIndex]: optionIndex // Update selected option for the question
        }));
    };

    // Generate an array of selected answers with question ID and selected answer index
    const getSelectedOptionsArray = () => {
        if(apiData.tets.type === "advance"){
            return apiData && apiData.tets.question.map((question, index) => ({
                id: question.id, // Use actual question ID here
                stdAns: [selectedOptions[index] !== undefined ? String(selectedOptions[index]) : null],
                isAttend: selectedOptions[index] !== undefined,
                question: question.question,
                option: question.option,
            }));
        }else{
            return apiData && apiData.tets.question.map((question, index) => ({
                id: question.id, // Use actual question ID here
                stdAns: [selectedOptions[index] !== undefined ? String(selectedOptions[index]) : null],
                isAttend: selectedOptions[index] !== undefined,
                ansId: question.ansId,
                question: question.question,
                option: question.option,
            }));
        }
    };

    const handleCheckAnswers = () => {
        setLoadingStart();
        const selectedArray = getSelectedOptionsArray();
        const data = {
            studentId: sessionStorage.getItem("userId"),
            testId: id,
            testType: apiData.tets.type && apiData.tets.type === 'advance'?'advance':'simple',
            question: selectedArray
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify(data);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/submitTest`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                let paresData = await response.json();
                if (response.status === 200) {
                    setSuccess("Quiz Complete successfully.");
                }else if (response.status === 401) {
                    setUnauthorized();
                  } else {
                    setErrors(paresData.message)
                }
            })
            .catch((error) => {
                setLoadingStop();
                setErrors(error);
                console.log(error);
            });

    };

    const setErrors = (error) => {
        scrollToTop();
        setIsError(true);
        setError(error);
        setTimeout(() => {
            setIsError(false);
            setError("");
        }, 2000);
    };

    const setSuccess = (msg) => {
        scrollToTop();
        setIsSuccess(true);
        setMsg(msg);
        setTimeout(() => {
            setIsSuccess(false);
            setMsg("");
            nav(-1)
        }, 2000);
    };

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callCheckTest();
        }
        if(isTestStart){
            callGetTest();
            const timerId = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime > 0) {
                        return prevTime - 1;
                    } else {
                        // Time is up, call the API
                        if (buttonRef.current) {
                            buttonRef.current.click(); // Trigger button click
                        }
                        clearInterval(timerId);
                        return 0; // Prevent negative time values
                    }
                }); 
            }, 1000);
    
            // Clear the interval when the component unmounts
            return () => clearInterval(timerId);
        }
    }, [isTestStart]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const callCheckTest = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
            "testId":id,
            "studentId":sessionStorage.getItem("userId")
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/istestCompleted`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                let paresData = await response.json();
                if (response.status === 200) {
                    setIsTestStart(!paresData.isCompleted);
                    setIsTestStarted(paresData.isTestStarted)
                    setIsConfirmation(paresData.isCompleted);
                }else if (response.status === 401) {
                    setUnauthorized();
                  } else {
                    setErrors(paresData.message)
                }
            })
            .catch((error) => {
                setLoadingStop();
                setErrors(error);
                console.log(error);
            });
    }

    const callGetTest = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getIdTest?id=${id}`, requestOptions)
            .then(async (response) => {
                setLoadingStop()
                if (response.status === 200) {
                    let paresData = await response.json();
                    setApiData(paresData);
                    setTime(paresData.tets.quizDuration * 60)
                    console.log("paresData-------", paresData);
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setLoadingStop()
                console.log(error);
            });
    }

    const onhide = () => {
        setIsConfirmation(false);
        setIsTestStarted(false);
      }

    return (
        <>
            <CompletedQuizDialog
                show={isConfirmation}
                onHide={() => onhide()}
            />
            <StartedQuizDialog
            show={!isTestStarted}
                onHide={() => onhide()}
            />
            <div className="position-fixed top-0 start-0 bg-dark text-white p-3" style={{ width: '75px', height: '100vh', overflow: "auto", paddingLeft: "0px !important", paddingRight: "0px !important", textAlign: "center" }}>
                <ul className="list-unstyled selection-list">
                    {apiData && apiData.tets.question.map((item, index) => {
                        const isAnswered = selectedOptions[index] !== undefined;
                        return (
                            <li key={index}>
                                <a
                                    href={`#${index + 1}`}
                                    className={`text-${isAnswered ? 'success' : selectedOptions[index] === null ? 'danger' : 'white'}`}
                                    onClick={(e) => {
                                        // Prevent default anchor behavior
                                        e.preventDefault();
                                        
                                        // Update the URL without causing scrolling or adding to history
                                        window.history.replaceState(null, '', `#${index + 1}`);
                                        
                                        // Optionally scroll to the target section
                                        const targetId = `#${index + 1}`;
                                        const targetElement = document.querySelector(targetId);
                                        if (targetElement) {
                                            targetElement.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    }}
                                >
                                    {index + 1}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>

            {apiData && <>

                <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark fixed-top">
                    <span className="navbar-brand ps-3">
                        {apiData.tets.titleName}
                    </span>

                    <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    </form>

                    <span style={{ color: "white" }} className={`navbar-nav ms-auto ms-md-0 me-3 me-lg-4 ${
            time <= 180 ? 'blinking-zoom-text' : ''
        }`}>
                        <b>{formatTime(time)}</b>
                    </span>
                </nav>

                {isError|| isSuccess ?<div style={{marginTop: "80px",marginLeft: "90px",marginRight: "21px"}}>
                    {isError ? <div
                        className={`alert alert-danger fade ${isError ? "show" : " "}`}
                        role="alert"
                    >
                        <strong>{error}</strong>
                    </div> : ''}

                    {isSuccess ? <div
                        className={`alert alert-success fade ${isSuccess ? "show" : " show"}`}
                        role="alert"
                    >
                        <strong>{msg}</strong>
                    </div> : ''}
                </div>:''}

                <div className="quiz-box" style={{ paddingTop: "65px", paddingLeft: "65px " }}>
                    <div className="title">Quiz</div>
                    <div className="box-wraper">
                        {apiData ?
                            <>
                                <div className="box">
                                    <div className="mb-3 row">
                                        <div className="col-sm-12">
                                            <label className="w-100 form-label justify-content-center" htmlFor={apiData.tets.titleName}><strong><h2>{apiData.tets.titleName}</h2></strong></label>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-6 d-flex">
                                            <div className="col-sm-12">
                                                <label className="w-100 form-label" htmlFor={apiData.tets.totalQue}><MyComponent apiData={apiData.tets.totalQue} /></label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 d-flex">
                                            <div className="col-sm-11">
                                                <label className="w-100 form-label" htmlFor={apiData.tets.quizTime}><MyComponent apiData={apiData.tets.quizTime} /></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="title">Instruction</div>
                                <div className="box">
                                    <div className="mb-3 row">
                                        <div className="col-sm-11">
                                            <label className="w-100 form-label" htmlFor={apiData.tets.instruction}><MyComponent apiData={apiData.tets.instruction} /></label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    apiData.tets.example && apiData.tets.example.some(item => item !== "") ? (apiData.tets.example.map((item, index) => (
                                        <>
                                            <div className="title">Example {index + 1}</div>
                                            <div id={index} key={index} className="box">
                                                <div className="mb-3 row">
                                                    <div className="col-sm-11">
                                                        <label className="w-100 form-label" htmlFor={item}><MyComponent apiData={item} /></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))) : (
                                        ''
                                    )}
                                {
                                    apiData.tets.question.map((item, index) => (
                                        <div id={index + 1}>
                                            <div className="title">Questions {index + 1}</div>
                                            <div key={index} className="box">
                                                <div className="mb-3 row">
                                                    <div className="w-100">
                                                        <label className="w-100 form-label" htmlFor={item.question}><MyComponent apiData={item.question} /></label>
                                                    </div>
                                                </div>

                                                <div class="mb-3 row">
                                                    <label class="col-sm-1 form-label align-items-start">Options</label>
                                                    <div class="col-sm-11 flex-wrap align-items-start pt-1">
                                                        {
                                                            item.option.map((itemOption, indexs) => (
                                                                <div key={indexs} class="form-check w-100">
                                                                    <input class="form-check-input" type="radio" value={itemOption.indicator} name={`flexRadioDefault${index}${indexs}`} checked={apiData.tets.type === "advance"?selectedOptions[index] === itemOption.indicator:selectedOptions[index] === indexs}
                                                                        onChange={(e) => {
                                                                            apiData.tets.type && apiData.tets.type === 'advance' ? (handleOptionChange(index, e.target.value)) : handleOptionChange(index, indexs)
                                                                        }} id={`flexRadioDefault${index}${indexs}`} />
                                                                    <label class="form-check-label" for={`flexRadioDefault${index}${indexs}`}>
                                                                        {itemOption.title}
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div style={{width: "100%",alignItems: "center",display: "flex",justifyContent: "center",marginTop: "15px"}}>
                                <Button ref={buttonRef} style={{width: "200px",height: "50px"}} onClick={handleCheckAnswers}>Final Submit</Button>
                                </div>
                            </> : ''}
                    </div>
                </div></>}

        </>
    )
}
