import React, { useContext } from "react";
import Footer from "./Footer";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import StudentList from "./StudentList";
import Home from "./Home";
import CreateStudent from "./CreateStudent";
import { PopupContext } from "../LoginPopupContext";
import TestLoginCreate from "./TestLoginCreate";
import TestAllocate from "./TestAllocate";
import TestAllocateRemove from "./TestAllocateRemove";
import AutoCompleteChips from "../AutoCompleteChips";
import StudentListTest from "./StudentListTest";
import AdminTestList from "./AdminTestList";
import ResetQuiz from "./ResetQuiz";
import Report from "./Report";
import DeleteStudents from "./DeleteStudents";

export default function AdminDashboard() {
  const { selectedMenu, setCurrentMenu,clearMenu } = useContext(PopupContext);
  return (
    <body className="sb-nav-fixed">
      <TopNav />
      <div id="layoutSidenav">
        <SideNav />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              {selectedMenu === 'Home' ? <Home /> : ''}
              {/* {selectedMenu === 'Report' ? <Report /> : ''} */}
              {selectedMenu === 'createStd' ? <CreateStudent /> : ''}
              {selectedMenu === 'stdList' ? <StudentList /> : ''}
              {selectedMenu === 'testLogin' ? <TestLoginCreate /> : ''}
              {selectedMenu === 'testAllocate' ? <TestAllocate /> : ''}
              {selectedMenu === 'removeTestAllocate' ? <TestAllocateRemove /> : ''}
              {selectedMenu === 'AllocatedStudents' ? <StudentListTest /> : ''}
              {selectedMenu === 'EditQuiz' ? <AdminTestList /> : ''}
              {selectedMenu === 'ResetQuiz' ? <ResetQuiz /> : ''}
              {selectedMenu === 'DeleteStudents' ? <DeleteStudents /> : ''}
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </body>
  );
}
