import React, { useContext, useEffect, useRef, useState } from 'react'
import { PopupContext } from '../LoginPopupContext';

export default function TestAllocateRemove() {
  const initialized = useRef(false);
  const [testTitle, setTestTitle] = useState();
  const [dataa, setData] = useState();
  const [selectTest, setSelectTest] = useState("");
  const [current, setCurrent] = useState();

  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { setUnauthorized } = useContext(PopupContext);

  const handleTestChange = (event) => {
    setSelectTest(event.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // for a smooth scrolling effect
    });
  };

  const reset = () => {
    scrollToTop();
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const setSuccess = (msg) => {
    setIsSuccess(true);
    setMsg(msg);
    setTimeout(() => {
      setIsSuccess(false);
      setMsg("");
    }, 2000);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getAllTestTitle();

    }
    if (selectTest !== '') {
      getAllData();
    }
  }, [selectTest]);

  const getAllTestTitle = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getAllTestTitle`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          let paresData = await response.json();
          setTestTitle(paresData);
          console.log("paresData-------", paresData);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getAllData = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const raw = JSON.stringify({
      testId: selectTest
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/testallocatedStudent`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          let paresData = await response.json();
          setData(paresData);
          reset();
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;

    return `${day}/${month}/${year}`;
  };

  const handleClick = (item) => {
    if (selectTest !== "") {
      const data = {
        "testId": selectTest,
        "studentId": item._id
      }
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("token")}`
      );
      const raw = JSON.stringify(data);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/deleteAllocateStudent`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          let paresData = await response.json();
          getAllData();
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }

  return (
    <div style={{ marginTop: "15px" }}>
      {isError ? <div
        className={`alert alert-danger fade ${isError ? "show" : " "}`}
        role="alert"
      >
        <strong>{error}</strong>
      </div> : ''}

      {isSuccess ? <div
        className={`alert alert-success fade ${isSuccess ? "show" : " "}`}
        role="alert"
      >
        <strong>{msg}</strong>
      </div> : ''}
      <div className="quiz-box">
        <div className="title">Quiz Allocation</div>
        <div className="box-wraper">
          <div className="box">
            <div className="mb-3 row">
              <div className="col-sm-11">
                <label className="w-100 form-label" htmlFor={`select`}>
                  Select Quiz
                </label>
                <select className="form-select" value={selectTest} onChange={handleTestChange} name="dropdown">
                  <option value="" selected>Select a Quiz</option>
                  {testTitle && testTitle.tets.map((item, index) => (
                    <option key={index} value={`${item._id}`}>{item.titleName}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {selectTest !== "" ? <div className="box">
            <div className="container-fluid px-4">
              <div className="row">
                <div className="card-body">
                  <table id="datatablesSimple" className="datatable-table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>School Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataa && dataa.users.length > 0 ? (
                        dataa.users.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.studentId && item.studentId.name}</td>
                            <td>{item.studentId && item.studentId.userName}</td>
                            <td>{item.studentId && item.studentId.nameSchool}</td>
                            <td><button className="btn btn-danger" onClick={() => handleClick(item.studentId)}>Remove</button></td>
                          </tr>
                        ))) : (<tr>
                          <td colSpan="6" style={{ textAlign: 'center' }}>Data not found</td>
                        </tr>)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> :
            <div className="box">
              <div className="container-fluid px-4">
                <div className="row">
                  Please Select Quiz!!!!
                </div>
              </div>
            </div>}

        </div>
      </div>
    </div>
  )
}
