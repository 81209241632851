import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MyComponent from '../testManager/MyComponent';
import { Button } from 'react-bootstrap';
import { PopupContext } from '../LoginPopupContext';

export default function StudentTestView() {
    const { id } = useParams();
    const initialized = useRef(false);
    const [apiData, setApiData] = useState();
    const nav = useNavigate();
    const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callGetTest();
        }
    }, []);

    const handleBack = () => {
        nav(-1);
    }

    const callGetTest = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/showResult?testId=${id}&studentId=${sessionStorage.getItem("userId")}`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                if (response.status === 200) {
                    let paresData = await response.json();
                    setApiData(paresData);
                    console.log("paresData-------", paresData);
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setLoadingStop();
                console.log(error);
            });
    }

    return (
        <div className="quiz-box">
            <div className="title">Quiz List</div>
            <div className="box-wraper">
                {apiData && apiData.test ?
                    <>
                        <div className="box">
                            <div className="mb-3 row">
                                <label className="col-sm-1 form-label">Quiz Title:</label>
                                <div className="col-sm-11">
                                    <label className="w-100 form-label" htmlFor={apiData.test.testId.titleName}>{apiData.test.testId.titleName}</label>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-1 form-label">Quiz Time:</label>
                                <div className="col-sm-11">
                                    <label className="w-100 form-label" htmlFor={apiData.test.testId.quizTime}><MyComponent apiData={apiData.test.testId.quizTime} /></label>
                                </div>
                            </div>
                        </div>
                        {
                            apiData.test.queAns.map((item, index) => (
                                <>
                                    <div className="title">Questions {index + 1}</div>
                                    <div key={index} className="box">
                                        <div className="mb-3 row">
                                            <div className="w-100">
                                            { item.stdAns.length === item.ansId.length && item.stdAns.every(ans => item.ansId.includes(ans)) ? <i class="fa-solid fa-circle-check" style={{color: "#00ff33"}}></i>:<i class="fa-solid fa-circle-xmark" style={{color: "#ff0000"}}></i>}
                                                <label className="w-100 form-label" htmlFor={item.question}><MyComponent apiData={item.question} /></label>
                                            </div>
                                        </div>

                                        <div class="mb-3 row">
                                            <label class="col-sm-1 form-label align-items-start">Options</label>
                                            <div class="col-sm-11 flex-wrap align-items-start pt-1">
                                                {
                                                    item.option.map((itemOption, indexs) => (
                                                        <div key={indexs} class="form-check w-100">
                                                            <input class="form-check-input" type="radio" checked={item.stdAns[0] == indexs} readOnly name={`flexRadioDefault${index}`} id={`flexRadioDefault${index}`} />
                                                            <label class="form-check-label" for={`flexRadioDefault${index}`}>
                                                                {indexs + 1}. {itemOption.title}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                    </> : 'Data Not Found!!!'}
                    <Button onClick={()=>handleBack()}>Back</Button>
            </div>
        </div>
    )
}
