import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { modules, formats, noImageformats, noImagemodules } from '../../testManager/Utils';
import ReactQuill from "react-quill";
import { PopupContext } from "../../LoginPopupContext";


function EditExmple(props) {
    const nav = useNavigate();
    const { setUnauthorized } = useContext(PopupContext);
    const [totalEx, setTotalEx] = useState(1);
    const [exList, setExList] = useState([]);

    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);
    const [instruction, setInstruction] = useState('');

    useEffect(() => {
        if (props && props.testInfo) {
            setTotalEx(props.testInfo.example.length);
            setExList(props.testInfo.example);
        }
    }, [props]);

    const handleExChange = (index) => (value) => {
        const newValues = [...exList];
        newValues[index] = value; // Update the value at the specific index
        setExList(newValues);
    };

    const handleEdit = () => {
        handleUpdate();
    };

    const handleAddExample = () => {
        setTotalEx(prevTotalEx => prevTotalEx + 1);
    }

    const handleSubExample = () => {
        setTotalEx(prevTotalEx => prevTotalEx - 1);
    }

    const handleUpdate = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
            id: props.testId,
            example: exList
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/updateTest`, requestOptions)
            .then(async (response) => {
                if (response.status === 200) {
                    props.addSuccessfully();
                    props.onHide();
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setErrors(error);
                console.log(error);
            });
    }

    const setErrors = (error) => {
        setIsError(true);
        setError(error);
        setTimeout(() => {
            setIsError(false);
            setError("");
        }, 2000);
    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update Quiz Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-wraper">
                        {Array.from({ length: totalEx }, (_, index) => (
                            <div key={index} className="box">
                                <div className="w-100">
                                    <div className="w-100">
                                        {1 < totalEx ? <span className='remove-btn' onClick={handleSubExample}><i class="fa-solid fa-trash-can" style={{ color: "#ff0000" }}></i></span> : ''}

                                        <ReactQuill
                                            value={exList[index] || ''}
                                            modules={modules}
                                            formats={formats}
                                            onChange={handleExChange(index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='box'>
                            <span className="btn btn-primary" onClick={handleAddExample}>+ Add</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditExmple;
