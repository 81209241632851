import React, { useContext, useEffect, useRef, useState } from "react";
import EditStudent from "./EditStudent";
import { PopupContext } from "../LoginPopupContext";
import { Button } from "react-bootstrap";
import { Margin } from "@mui/icons-material";
import DeleteStudentDialog from "./DeleteStudentDialog";
import FileUploadModal from "./FileUploadModal";
import * as XLSX from 'xlsx';
import { Chip, Box } from '@mui/material';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { CheckCircle } from "@mui/icons-material";

export default function StudentListTest() {
  const [loading, setLoading] = useState(false);
  const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

  const initialized = useRef(false);
  const [dataa, setData] = useState([]);
  const [schoolName, setSchoolName] = useState();
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedStstus, setSelectedStatus] = useState('Pending');
  const [currentPage, setCurrentPage] = useState(0);
  const [loadmoreShow, setLoadMoreShow] = useState(false);

  const [selectedTab, setSelectedTab] = useState("All"); // Default active tab
  const [inputValue, setInputValue] = useState("");

  const handleClear = () => {
    setInputValue("");
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    getAllData(selectedSchool,selectedStstus,0,inputValue);
  };


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getAllData("",selectedStstus,currentPage,inputValue);
      getSchoolName();
    }
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    getAllData(selectedSchool, selectedStstus,0, value);
  };

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
  };


  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
    getAllData(selectedSchool,selectedStstus,currentPage + 1,inputValue);
  }

  const getAllData = (selectedSchool,selectedStstus,page,value) => {    
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      selectedSchool === '' ? `${process.env.REACT_APP_BASE_URL}/api/v1/test/gettestallocatedstudentdetails?status=${selectedStstus}&page=${page}${
        selectedTab === "All" && value !== undefined ? "" : `&${selectedTab}=${value}`
      }`
        :
        `${process.env.REACT_APP_BASE_URL}/api/v1/test/gettestallocatedstudentdetails?nameSchool=${selectedSchool}&status=${selectedStstus}&page=${page}${
          selectedTab === "All" && value !== undefined ? "" : `&${selectedTab}=${value}`
        }`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          if(paresData.users.length > 0){
            setLoadMoreShow(true);
          }else{
            setLoadMoreShow(false);
          }
          setData((prevData) => {
            // Append new users to the existing array of users
            return [...prevData, ...paresData.users];
          });
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  };

  const getSchoolName = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/getSchoolName`, requestOptions)
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setSchoolName(paresData.schoolList);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;

    return `${day}/${month}/${year}`;
  };

  const handleSchoolChange = (event) => {
    setCurrentPage(0);
    setData([]);
    setLoadMoreShow(false);
    setSelectedSchool(event.target.value);
    getAllData(event.target.value,selectedStstus,0,inputValue);
  };

  const handleStatusChange = (event) => {
    setCurrentPage(0);
    setData([]);
    setLoadMoreShow(false);
    setSelectedStatus(event.target.value);
    getAllData(selectedSchool,event.target.value,0,inputValue);
  };

  const handleExportFile = () =>{
    setLoadingStart();
    const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
        myHeaders.append("Accept",'text/csv');
    
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };
    
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getreport2excelfile?status=${selectedStstus}${selectedSchool !== '' ? `&nameSchool=${selectedSchool}`:''}`, requestOptions)
          .then(async (response) => {
            setLoadingStop();
            if (response.status === 200) {
            // Check if the response content is gzip-compressed
            const contentEncoding = response.headers.get('Content-Encoding');
            let data = await response.blob();
    
            // If the response is gzip-compressed, we need to decompress it (optional, depends on the API)
            if (contentEncoding === 'gzip') {
              const arrayBuffer = await data.arrayBuffer();
              const decompressedData = new TextDecoder().decode(new Uint8Array(arrayBuffer));
              data = new Blob([decompressedData], { type: 'text/csv' });
            }
    
            // Create a URL for the blob object and trigger the download
            const blobUrl = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = blobUrl;
    
            // Optional: Get the filename from the content-disposition header or provide a default one
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = `Student${selectedStstus}Data_${formatDate(Date.now())}.csv`;
            if (contentDisposition) {
              const match = contentDisposition.match(/filename="([^"]+)"/);
              if (match) {
                filename = match[1];
              }
            }
    
            // Set the filename and trigger the download
            link.download = filename;
            link.click();
    
            // Clean up the URL object after the download
            URL.revokeObjectURL(blobUrl);
            }else if (response.status === 401) {
               
                setUnauthorized();
            }
          })
          .catch((error) => {
            setLoadingStop();
            console.log(error);
          });
  }

  return (
    <main>
      <div style={{ marginTop: "10px" }}>
        {isError ? (
          <div
            className={`alert alert-danger fade ${isError ? "show" : " "}`}
            role="alert"
          >
            <strong>{error}</strong>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="quiz-box">
        <div className="title">Allocated Quiz Students List <Button onClick={handleExportFile}><i class="fa-solid fa-download"></i> Export</Button></div>
        <div className="box-wraper">
          <div className="box">
            <div className="row">
              <div className="card-body">

              <span
                  style={{ display: "flex", gap: "8px", marginLeft: "10px" }}
                >
                  Filter On
                </span>
                <div
                  style={{ display: "flex", gap: "8px", marginLeft: "10px" }}
                >
                  <Chip
                    variant={selectedTab === "All" ? "filled" : "outlined"}
                    label="All"
                    color="success"
                    icon={selectedTab === "All" ? <CheckCircle /> : null}
                    onClick={() => (handleTabChange("All"),handleClear())}
                  />
                  <Chip
                    variant={selectedTab === "name" ? "filled" : "outlined"}
                    label="Name"
                    color="success"
                    icon={selectedTab === "name" ? <CheckCircle /> : null}
                    onClick={() => handleTabChange("name")}
                  />
                  <Chip
                    variant={selectedTab === "Class" ? "filled" : "outlined"}
                    label="Class"
                    color="success"
                    icon={selectedTab === "Class" ? <CheckCircle /> : null}
                    onClick={() => handleTabChange("Class")}
                  />
                  <Chip
                    variant={selectedTab === "stream" ? "filled" : "outlined"}
                    label="Stream"
                    color="success"
                    icon={selectedTab === "stream" ? <CheckCircle /> : null}
                    onClick={() => handleTabChange("stream")}
                  />
                  {selectedTab === "All" ? (
                    ""
                  ) : (
                    <div>
                      <TextField
                        value={inputValue}
                        onChange={handleSearchChange}
                        placeholder={`search by ${selectedTab}`}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon color="action" />
                            </InputAdornment>
                          ),
                          endAdornment: inputValue && (
                            <InputAdornment position="end">
                              <CloseIcon
                                onClick={() => handleClear()}
                                style={{ cursor: "pointer", color: "gray" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                </div>

                <div style={{ marginBottom: "10px",
                    marginTop: "10px",
                    marginLeft: "10px", }}>
                  <select className="form-select" value={selectedSchool} onChange={handleSchoolChange} name="dropdown">
                    <option value="">-----Filter School------</option>
                    {schoolName && schoolName.map((item, index) => (
                      <option key={index} value={`${item}`}>{item}</option>
                    ))}
                  </select>
                  </div>
                  <div style={{ marginBottom: "10px",marginTop: "10px",
                    marginLeft: "10px", }}>
                  <select className="form-select" value={selectedStstus} onChange={handleStatusChange} name="dropdown">
                    <option value="Pending">Pending</option>
                    <option value="Started">Started</option>
                    <option value="Completed">Completed</option>
                  </select>
                  </div>
                <table id="datatablesSimple" className="datatable-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Class</th>
                      <th>Stream</th>
                      <th>School Name</th>
                      {selectedStstus === 'Pending'?<th>Pending Quiz</th>:''}
                      {selectedStstus === 'Started'?<th>Started Quiz</th>:''}
                      {selectedStstus === 'Completed'?<th>Completed Quiz</th>:''}
                    </tr>
                  </thead>
                  <tbody>
                    {dataa &&
                      dataa.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.userName}</td>
                          <td>{item.Class}</td>
                          <td>{item.stream}</td>
                          <td>{item.nameSchool}</td>
                          {selectedStstus === 'Pending'?<td>{item.testStringPending.split(',').map((item,index)=>(
                            <Chip
                              key={index}
                              label={item} // Display label based on value
                              sx={{
                                  margin: '5px',
                                  backgroundColor: '#ff9f15', // Dark gray background for chip
                                  color: '#fff', // White text for the chip
                              }}
                          />
                          ))}</td>:''}
                          {selectedStstus === 'Started'?<td>
                          {item.testStringStarted.split(',').map((item,index)=>(
                            <Chip
                              key={index}
                              label={item} // Display label based on value
                              sx={{
                                  margin: '5px',
                                  backgroundColor: '#555555', // Dark gray background for chip
                                  color: '#fff', // White text for the chip
                              }}
                          />
                          ))}</td>:''}
                          {selectedStstus === 'Completed'?<td>
                          {item.testStringCompleted.split(',').map((item,index)=>(
                            <Chip
                              key={index}
                              label={item} // Display label based on value
                              sx={{
                                  margin: '5px',
                                  backgroundColor: '#1b7719', // Dark gray background for chip
                                  color: '#fff', // White text for the chip
                              }}
                          />
                          ))}</td>:''}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                  <Button disabled={!loadmoreShow} variant="primary" onClick={handleNextPage}>Next Page</Button>
                </div>
          </div>
        </div>
      </div>
    </main>
  );
}
