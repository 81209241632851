import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import MyComponent from '../testManager/MyComponent';
import { PopupContext } from '../LoginPopupContext';
import EditInfo from './popups/EditInfo';
import EditInstruction from './popups/EditInstruction';
import EditExmple from './popups/EditExmple';
import EditQuestions from './popups/EditQuestions';
import { Button } from 'react-bootstrap';
import AddQuestions from './popups/AddQuestions';
import DeleteQue from './popups/DeleteQue';

export default function AdminTestEditView() {
    const { id } = useParams();
    const initialized = useRef(false);
    const [apiData, setApiData] = useState();
    const [testInfo, setTestInfo] = useState();
    const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);
    const [isInfo, setIsInfo] = useState(false);
    const [isEditInstruction, setIsEditInstruction] = useState(false);
    const [isExmple, setIsExmple] = useState(false);
    const [addedName, setAddedName] = useState(false);
    const [isQuestion, setIsQuestion] = useState(false);
    const [isQuestionDelete, setIsQuestionDelete] = useState(false);
    const [currentQue, setcurrentQue] = useState();
    const [isQuestionAdd, setIsQuestionAdd] = useState(false);
    const nav = useNavigate();

    const [currentQuestion, setCurrentQuestion] = useState();

    const addSuccessfully = () => {
        setAddedName(true);
        setcurrentQue();
        setTimeout(() => {
            setAddedName(false);
        }, 1000);
    };

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callGetTest();
        }
        if (addedName) {
            callGetTest();
        }
    }, [addedName]);

    const callGetTest = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getIdTest?id=${id}`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                if (response.status === 200) {
                    let paresData = await response.json();
                    setApiData(paresData);
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setLoadingStop();
                console.log(error);
            });
    }

    const handleInfo = (item) => {
        setIsInfo(true);
        setTestInfo(item)
    }
    const handleInstruction = (item) => {
        setIsEditInstruction(true);
        setTestInfo(item)
    }

    const handleExample = (item) => {
        setIsExmple(true);
        setTestInfo(item)
    }

    const handleQues = (item) => {
        setIsQuestion(true);
        setCurrentQuestion(item)
    }

    const handleQuesDelete = (index) => {
        setIsQuestionDelete(true);
        setcurrentQue(index)
    }

    const handleQuesAdd = () => {
        setIsQuestionAdd(true);
    }

    const handleBack = () => {
        nav(-1);
    }

    return (
        <>
            <EditInfo
                show={isInfo}
                onHide={() => setIsInfo(false)}
                testInfo={testInfo}
                testId={id}
                addSuccessfully={addSuccessfully} />

            <EditInstruction
                show={isEditInstruction}
                onHide={() => setIsEditInstruction(false)}
                testInfo={testInfo}
                testId={id}
                addSuccessfully={addSuccessfully} />

            <EditExmple
                show={isExmple}
                onHide={() => setIsExmple(false)}
                testInfo={testInfo}
                testId={id}
                addSuccessfully={addSuccessfully} />

            <EditQuestions
                show={isQuestion}
                onHide={() => setIsQuestion(false)}
                currentQuestion={currentQuestion}
                testId={id}
                addSuccessfully={addSuccessfully} />

            <AddQuestions
                show={isQuestionAdd}
                onHide={() => setIsQuestionAdd(false)}
                testId={id}
                addSuccessfully={addSuccessfully} />

            <DeleteQue
                show={isQuestionDelete}
                onHide={() => setIsQuestionDelete(false)}
                testId={id}
                queId={currentQue}
                addSuccessfully={addSuccessfully} />

            <div className="quiz-box">
                <div className="title">{apiData && apiData.tets.titleName}<span onClick={() => handleInfo(apiData && apiData.tets)}><i class="fa-solid fa-file-pen" style={{ color: "#ffffff" }}></i></span></div>
                <div className="box-wraper">
                    {apiData ?
                        <>
                            <div className="box">
                                <div className="mb-3 row">
                                    <label className="col-sm-1 form-label">Quiz Title:</label>
                                    <div className="col-sm-11">
                                        <label className="w-100 form-label" htmlFor={apiData.tets.titleName}>{apiData.tets.titleName}</label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-1 form-label">Total Questions:</label>
                                    <div className="col-sm-11">
                                        <label className="w-100 form-label" htmlFor={apiData.tets.totalQue}><MyComponent apiData={apiData.tets.totalQue} /></label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-1 form-label">Quiz Time:</label>
                                    <div className="col-sm-11">
                                        <label className="w-100 form-label" htmlFor={apiData.tets.quizTime}><MyComponent apiData={apiData.tets.quizTime} /></label>
                                    </div>
                                </div>
                            </div>
                            <div className="title">Instruction <span onClick={() => handleInstruction(apiData && apiData.tets)}><i class="fa-solid fa-file-pen" style={{ color: "#ffffff" }}></i></span></div>
                            <div className="box">
                                <div className="mb-3 row">
                                    <div className="col-sm-11">
                                        <label className="w-100 form-label" htmlFor={apiData.tets.instruction}><MyComponent apiData={apiData.tets.instruction} /></label>
                                    </div>
                                </div>
                            </div>
                            {
                                apiData.tets.example && apiData.tets.example.some(item => item !== "") ? (apiData.tets.example.map((item, index) => (
                                    <>
                                        <div className="title">Example {index + 1} <span onClick={() => handleExample(apiData && apiData.tets)}><i class="fa-solid fa-file-pen" style={{ color: "#ffffff" }}></i></span></div>
                                        <div id={index} key={index} className="box">
                                            <div className="mb-3 row">
                                                <div className="col-sm-11">
                                                    <label className="w-100 form-label" htmlFor={item}><MyComponent apiData={item} /></label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))) : (
                                    ""
                                )}
                            {
                                apiData.tets.question.map((item, index) => (
                                    <div id={index + 1} className="section">
                                        <div className="title">Questions {index + 1} <span><span onClick={() => handleQuesDelete(index)}><i class="fa-solid fa-trash-can" style={{ color: "#ff0000" }}></i></span>   <span onClick={() => handleQues(item)}><i class="fa-solid fa-file-pen" style={{ color: "#ffffff" }}></i></span></span></div>
                                        <div key={index} className="box">
                                            <div className="mb-3 row">
                                                <div className="w-100">
                                                    <label className="w-100 form-label" htmlFor={item.question}><MyComponent apiData={item.question} /></label>
                                                </div>
                                            </div>

                                            <div class="mb-3 row">
                                                <label class="col-sm-1 form-label align-items-start">Options</label>
                                                <div class="col-sm-11 flex-wrap align-items-start pt-1">
                                                    {
                                                        item.option.map((itemOption, indexs) => (
                                                            <div key={indexs} class="form-check w-100">
                                                                <input class="form-check-input" type="radio" checked={item.ansId[0] == indexs} readOnly name={`flexRadioDefault${index}`} id={`flexRadioDefault${index}`} />
                                                                <label class="form-check-label" for={`flexRadioDefault${index}`}>
                                                                    {indexs + 1}. {itemOption.title}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <Button variant="primary" style={{marginTop:'10px'}} onClick={handleQuesAdd}>Add Question</Button>
                        </> : ''}
                </div>
                <Button variant="primary" onClick={handleBack}>BACK</Button>
            </div>
        </>
    )
}
