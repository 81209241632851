import React from "react";
import App from "./App";
import { LoginPopupProvider } from "./component/LoginPopupContext"
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from 'react-dom/client';

// const root = ReactDOM.createRoot(document.getElementById('root'));
const root = createRoot(document.getElementById('root'));
root.render(
    <LoginPopupProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </LoginPopupProvider>
);
