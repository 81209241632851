import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { PopupContext } from '../LoginPopupContext';

export default function STopNav() {
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("userinfo")));
  const { selectedMenu, setCurrentMenu,clearMenu ,setLogOut} = useContext(PopupContext);

  const handleLogout = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/logOut`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
      sessionStorage.clear();
      localStorage.clear();
      setLogOut();
      navigate("/");
      clearMenu();
  }

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <a className="navbar-brand ps-3" href="#">
        <img src='/logo_png.png' height={'30px'} width={'122px'} style={{filter: "drop-shadow(6px 13px 13px white) brightness(1.2)", pointerEvents: "none"}}/>
      </a>
      <button
        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
        href="#!"
      >
        <i className="fas fa-bars"></i>
      </button>

      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
      <span style={{color: "white"}}>Username: {user.userName}</span>
      </form>

      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-user fa-fw"></i>
          </a>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
          <li style={{
            pointerEvents: "none",
            opacity: 0.5,
            cursor: "not-allowed",
          }}>
            <span className="dropdown-item">
              <div><b>Username:</b> {user.userName}</div>
              <div><b>Name:</b> {user.name}</div>
              <div><b>School Name:</b> {user.nameSchool}</div>
            </span>
          </li>
            <li>
              <span className="dropdown-item" onClick={handleLogout}>
                Logout
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  )
}
