import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import MyComponent from './MyComponent';
import { PopupContext } from '../LoginPopupContext';

export default function TestViewIndicator() {
    const { id } = useParams();
    const initialized = useRef(false);
    const [apiData, setApiData] = useState();
    const { setLoadingStart, setLoadingStop, setUnauthorized } = useContext(PopupContext);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callGetTest();
        }
    }, []);

    const callGetTest = () => {
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getIdTest?id=${id}`, requestOptions)
            .then(async (response) => {
                setLoadingStop();
                if (response.status === 200) {
                    let paresData = await response.json();
                    setApiData(paresData);
                    console.log("paresData-------", paresData);

                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setLoadingStop();
                console.log(error);
            });
    }

    return (
        <div className="quiz-box">
            <div className="title">Quiz List</div>
            <div className="box-wraper">
                {apiData ?
                    <>
                        <div className="box">
                            <div className="mb-3 row">
                                <div className="col-sm-12">
                                    <label className="w-100 form-label justify-content-center" htmlFor={apiData.tets.titleName}><strong><h2>{apiData.tets.titleName}</h2></strong></label>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <div className="col-sm-6 d-flex">
                                    <div className="col-sm-12">
                                        <label className="w-100 form-label" htmlFor={apiData.tets.totalQue}><MyComponent apiData={apiData.tets.totalQue} /></label>

                                    </div>
                                </div>
                                <div className="col-sm-6 d-flex">
                                    <div className="col-sm-11">
                                        <label className="w-100 form-label" htmlFor={apiData.tets.quizTime}><MyComponent apiData={apiData.tets.quizTime} /></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="title">Instruction</div>
                        <div className="box">
                            <div className="mb-3 row">
                                <div className="col-sm-11">
                                    <label className="w-100 form-label" htmlFor={apiData.tets.instruction}><MyComponent apiData={apiData.tets.instruction} /></label>
                                </div>
                            </div>
                        </div>
                        {
                            apiData.tets.example && apiData.tets.example.some(item => item !== "") ? (apiData.tets.example.map((item, index) => (
                                <>
                                    <div className="title">Example {index + 1}</div>
                                    <div id={index} key={index} className="box">
                                        <div className="mb-3 row">
                                            <div className="col-sm-11">
                                                <label className="w-100 form-label" htmlFor={item}><MyComponent apiData={item} /></label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))) : (
                                ""
                            )}
                        {
                            apiData.tets.question.map((item, index) => (
                                <div id={index + 1} className="section">
                                    <div className="title">Questions {index + 1}</div>
                                    <div key={index} className="box">
                                        <div className="mb-3 row">
                                            <div className="w-100">
                                                <label className="w-100 form-label" htmlFor={item.question}><MyComponent apiData={item.question} /></label>
                                            </div>
                                        </div>

                                        <div class="mb-3 row">
                                            <label class="col-sm-1 form-label align-items-start">Options</label>
                                            <div class="col-sm-11 flex-wrap align-items-start pt-1">
                                                {
                                                    item.option.map((itemOption, indexs) => (
                                                        <div key={indexs} class="form-check w-100">
                                                            <label style={{ whiteSpace: "pre-line", marginTop: "20px" }}>{`${indexs + 1}. ${itemOption.title}`}</label>
                                                            <label style={{ marginLeft: "15px" }}><u>Indicator:<b>{itemOption.indicator}</b></u></label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </> : ''}
            </div>
        </div>
    )
}
