import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { CheckCircle } from "@mui/icons-material";
import { Chip } from "@mui/material";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { PopupContext } from "../LoginPopupContext";
import { Link } from "react-router-dom";
import ExportFile from "./popups/ExportFile";

export default function Home() {
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isExportFile, setIsExportFile] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [schoolName, setSchoolName] = useState();
  const initialized = useRef(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedSchool, setSelectedSchool] = useState("");
  const { setLoadingStart, setLoadingStop, setUnauthorized } =
    useContext(PopupContext);
  const [loadmoreShow, setLoadMoreShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataa, setData] = useState([]);
  const pairs = [
    ["E", "I"],
    ["S", "N"],
    ["T", "F"],
    ["J", "P"],
  ];

  function getHighestIndicatorsWithValues(response, pairs) {
    // Map the response data for quick lookup by name
    const valueMap = response.reduce((map, item) => {
      map[item.name] = item.value;
      return map;
    }, {});

    // Compare values in each pair and choose the one with the higher value
    return pairs.map(([first, second]) => {
      const higherIndicator =
        valueMap[first] >= valueMap[second] ? first : second;
      return { name: higherIndicator, value: valueMap[higherIndicator] };
    });
  }

  const handleClear = () => {
    setInputValue("");
    setInputValue("");
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    getReport(0, selectedSchool, "");
  };
  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleSchoolChange = (event) => {
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    setSelectedSchool(event.target.value);
    getReport(0, event.target.value);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    getReport(0, selectedSchool, value);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    getReport(currentPage + 1, selectedSchool);
    // getAllData(selectedSchool,currentPage + 1,inputValue);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getSchoolName();
      getReport(currentPage, "");
    }
  }, []);

  const getSchoolName = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/getSchoolName`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setSchoolName(paresData.schoolList);
        } else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  };

  const getReport = (page, schoolName, search) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/test/report?page=${page}${
        schoolName !== "" ? `&nameSchool=${schoolName}` : ""
      }${selectedTab === "All" ? "" : `&${selectedTab}=${search}`}`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          if (paresData.studentlist.length > 0) {
            setLoadMoreShow(true);
          } else {
            setLoadMoreShow(false);
          }
          setData((prevData) => {
            return [...prevData, ...paresData.studentlist];
          });
        } else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  };

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes}:${seconds} ${ampm}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  };

  const handleReferace = () => {
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    getReport(0, selectedSchool, inputValue);
  };

  const handleDownload = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
    myHeaders.append("Accept",'text/csv');

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getreportexcelfile?date=2024-11-21`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
        // Check if the response content is gzip-compressed
        const contentEncoding = response.headers.get('Content-Encoding');
        let data = await response.blob();

        // If the response is gzip-compressed, we need to decompress it (optional, depends on the API)
        if (contentEncoding === 'gzip') {
          const arrayBuffer = await data.arrayBuffer();
          const decompressedData = new TextDecoder().decode(new Uint8Array(arrayBuffer));
          data = new Blob([decompressedData], { type: 'text/csv' });
        }

        // Create a URL for the blob object and trigger the download
        const blobUrl = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = blobUrl;

        // Optional: Get the filename from the content-disposition header or provide a default one
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'StudentReportData.csv';
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="([^"]+)"/);
          if (match) {
            filename = match[1];
          }
        }

        // Set the filename and trigger the download
        link.download = filename;
        link.click();

        // Clean up the URL object after the download
        URL.revokeObjectURL(blobUrl);

          let paresData = await response.json();
          console.log("paresData-------", paresData);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
    <ExportFile
     show={isExportFile}
     onHide={() => setIsExportFile(false)}/>

      <main>
        <div style={{ marginTop: "10px" }}>
          {isError ? (
            <div
              className={`alert alert-danger fade ${isError ? "show" : " "}`}
              role="alert"
            >
              <strong>{error}</strong>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="quiz-box">
          <div className="title">
            Reports{" "}
            <div>
              <Button onClick={()=>setIsExportFile(true)}>
                <i class="fa-solid fa-download"></i> Export
              </Button>{" "}
              <Button
                style={{ marginLeft: "5px" }}
                onClick={handleReferace}
                variant="secondary"
              >
                <i class="fa-solid fa-rotate-right"></i>
              </Button>
            </div>
          </div>
          <div className="box-wraper">
            <div className="box">
              <div className="row">
                <div className="card-body">
                  <span
                    style={{ display: "flex", gap: "8px", marginLeft: "10px" }}
                  >
                    Filter On
                  </span>
                  <div
                    style={{ display: "flex", gap: "8px", marginLeft: "10px" }}
                  >
                    <Chip
                      variant={selectedTab === "All" ? "filled" : "outlined"}
                      label="All"
                      color="success"
                      icon={selectedTab === "All" ? <CheckCircle /> : null}
                      onClick={() => {
                        handleTabChange("All");
                        handleClear();
                      }}
                    />
                    <Chip
                      variant={selectedTab === "name" ? "filled" : "outlined"}
                      label="Name"
                      color="success"
                      icon={selectedTab === "name" ? <CheckCircle /> : null}
                      onClick={() => handleTabChange("name")}
                    />
                    <Chip
                      variant={selectedTab === "Class" ? "filled" : "outlined"}
                      label="Class"
                      color="success"
                      icon={selectedTab === "Class" ? <CheckCircle /> : null}
                      onClick={() => handleTabChange("Class")}
                    />
                    <Chip
                      variant={selectedTab === "stream" ? "filled" : "outlined"}
                      label="Stream"
                      color="success"
                      icon={selectedTab === "stream" ? <CheckCircle /> : null}
                      onClick={() => handleTabChange("stream")}
                    />
                    {selectedTab === "All" ? (
                      ""
                    ) : (
                      <div>
                        <TextField
                          value={inputValue}
                          onChange={handleSearchChange}
                          placeholder={`search by ${selectedTab}`}
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon color="action" />
                              </InputAdornment>
                            ),
                            endAdornment: inputValue && (
                              <InputAdornment position="end">
                                <CloseIcon
                                  onClick={() => handleClear()}
                                  style={{ cursor: "pointer", color: "gray" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <select
                      className="form-select"
                      value={selectedSchool}
                      onChange={handleSchoolChange}
                      name="dropdown"
                    >
                      <option value="">-----Filter School------</option>
                      {schoolName &&
                        schoolName.map((item, index) => (
                          <option key={index} value={`${item}`}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                  <table id="datatablesSimple" className="datatable-table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Class</th>
                        <th>Stream</th>
                        <th>School Name</th>
                        <th>Quiz Name</th>
                        <th>Marks</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataa &&
                        dataa.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{formatDate(item.endTime)}</td>
                            <td>{item.userDetails.name}</td>
                            <td>{item.userDetails.userName}</td>
                            <td>
                              {item.userDetails.Class === ""
                                ? "-"
                                : item.userDetails.Class}
                            </td>
                            <td>
                              {item.userDetails.stream === ""
                                ? "-"
                                : item.userDetails.stream}
                            </td>
                            <td>{item.userDetails.nameSchool}</td>
                            <td>{item.testDetalis.titleName}</td>
                            <td>
                              {item.testDetalis.type === "advance" &&
                              Array.isArray(item.indiResult) &&
                              item.indiResult.length > 0 ? (
                                getHighestIndicatorsWithValues(
                                  item.indiResult,
                                  pairs
                                ).map((resItem, indexz) => (
                                  <span key={indexz}>
                                    {"  "}
                                    <b>{resItem.name}:</b> {resItem.value}
                                    {indexz === 1 && <br />}
                                  </span>
                                ))
                              ) : (
                                <td>{item.result}</td>
                              )}
                            </td>
                            <td>
                              <Link
                                to={
                                  item.testDetalis.type === "advance"
                                    ? `/adminMBTITstView/${item.testId}/${item.studentId}`
                                    : `/adminTstView/${item.testId}/${item.studentId}`
                                }
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      disabled={!loadmoreShow}
                      variant="primary"
                      onClick={handleNextPage}
                    >
                      Next Page
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
