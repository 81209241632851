import React, { useContext, useEffect, useRef, useState } from "react";
import { PopupContext } from "../LoginPopupContext";
import { Button } from "react-bootstrap";
import DeleteAllRecordsDialog from "./DeleteAllRecordsDialog";

export default function DeleteStudents() {
  const initialized = useRef(false);
  const { setLoadingStart, setLoadingStop, setUnauthorized } =
    useContext(PopupContext);
  const [schoolName, setSchoolName] = useState();
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [isDeleteShow, setIsDeleteShow] = useState("");
  const [currentItem, setCurrentItem] = useState("");

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getSchoolName();
    }
  }, [selectedSchool]);

  const getSchoolName = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/getSchoolName`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setSchoolName(paresData.schoolList);
        } else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  };

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const handleSchoolChange = (event) => {
    setCurrentItem(selectedSchool);
    setSelectedSchool(event.target.value);
  };

  const handleDelete = () => {
    setIsDeleteShow(true);
  };

  return (
    <main>
      <DeleteAllRecordsDialog
        show={isDeleteShow}
        nameSchool={selectedSchool}
        onHide={() => setIsDeleteShow(false)}
      />
      <div style={{ marginTop: "10px" }}>
        {isError ? (
          <div
            className={`alert alert-danger fade ${isError ? "show" : " "}`}
            role="alert"
          >
            <strong>{error}</strong>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="quiz-box">
        <div className="title">Delete Students List</div>
        <div className="box-wraper">
          <div className="box">
            <div className="row">
              <div className="card-body">
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <p>
                    Select School and delete now button click to delete selected
                    school student data delete and related test Data deleted.
                  </p>

                  <select
                    className="form-select"
                    value={selectedSchool}
                    onChange={handleSchoolChange}
                    name="dropdown"
                  >
                    <option value="">-----Filter School------</option>
                    {schoolName &&
                      schoolName.map((item, index) => (
                        <option key={index} value={`${item}`}>
                          {item}
                        </option>
                      ))}
                  </select>

                  <Button
                    variant="danger"
                    style={{ marginTop: "10px" }}
                    disabled={schoolName === ""}
                    onClick={handleDelete}
                  >
                    Delete Records
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
