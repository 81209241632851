import React, { useContext, useEffect, useRef, useState } from "react";
import EditStudent from "./EditStudent";
import { PopupContext } from "../LoginPopupContext";
import { Button } from "react-bootstrap";
import { CheckCircle } from "@mui/icons-material";
import { Chip } from "@mui/material";

import DeleteStudentDialog from "./DeleteStudentDialog";
import FileUploadModal from "./FileUploadModal";
import * as XLSX from "xlsx";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export default function StudentList() {
  const [loading, setLoading] = useState(false);
  const { setLoadingStart, setLoadingStop, setUnauthorized } =
    useContext(PopupContext);

  const initialized = useRef(false);
  const [dataa, setData] = useState([]);
  const [schoolName, setSchoolName] = useState();
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isEditShow, setIsEditShow] = useState(false);
  const [isDeleteShow, setIsDeleteShow] = useState(false);
  const [isImportShow, setIsImportShow] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [loadmoreShow, setLoadMoreShow] = useState(false);

  const [currentItem, setCurrentItem] = useState();
  const [addedName, setAddedName] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const handleClear = () => {
    setInputValue("");
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    getAllData(selectedSchool, currentPage, "");
  };

  const [selectedTab, setSelectedTab] = useState("All"); // Default active tab

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleExport = () => {
    // Get the table element
    const table = document.getElementById("datatablesSimple");

    // Create a workbook and a worksheet from the table data
    const ws = XLSX.utils.table_to_sheet(table, { raw: true });

    // Remove the last column (Action) by deleting the last column in the worksheet
    const range = XLSX.utils.decode_range(ws["!ref"]); // Get the range of the worksheet
    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellAddress = { r: row, c: range.e.c }; // Last column cell address
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      delete ws[cellRef]; // Delete the Action column cell
    }

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook to a file
    XLSX.writeFile(wb, "table-data.xlsx");
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getAllData("", currentPage);
      getSchoolName();
    }
    if (addedName) {
      setData([]);
      getAllData(selectedSchool, currentPage);
      getSchoolName();
    }
  }, [addedName]);

  const exportData = (name) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",`Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify({
      nameSchool: name,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/getStudentListExcel`,
      requestOptions
    )
    .then(async (response) => {
      if (response.status === 200) {
      // Check if the response content is gzip-compressed
      const contentEncoding = response.headers.get('Content-Encoding');
      let data = await response.blob();

      // If the response is gzip-compressed, we need to decompress it (optional, depends on the API)
      if (contentEncoding === 'gzip') {
        const arrayBuffer = await data.arrayBuffer();
        const decompressedData = new TextDecoder().decode(new Uint8Array(arrayBuffer));
        data = new Blob([decompressedData], { type: 'text/csv' });
      }

      // Create a URL for the blob object and trigger the download
      const blobUrl = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = blobUrl;

      // Optional: Get the filename from the content-disposition header or provide a default one
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = `${name}_StudentList.csv`;
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="([^"]+)"/);
        if (match) {
          filename = match[1];
        }
      }

      // Set the filename and trigger the download
      link.download = filename;
      link.click();

      // Clean up the URL object after the download
      URL.revokeObjectURL(blobUrl);

        let paresData = await response.json();
        console.log("paresData-------", paresData);
      }else if (response.status === 401) {
        setUnauthorized();
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const getAllData = (selectedSchool, pagee, value) => {
    console.log(selectedSchool, "------selectedSchool");

    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      selectedSchool === ""
        ? `${
            process.env.REACT_APP_BASE_URL
          }/api/v1/users/getAllUsers?roleType=Student&page=${pagee}${
            selectedTab === "All" ? "" : `&${selectedTab}=${value}`
          }`
        : `${
            process.env.REACT_APP_BASE_URL
          }/api/v1/users/getAllUsers?roleType=Student&nameSchool=${selectedSchool}&page=${pagee}${
            selectedTab === "All" ? "" : `&${selectedTab}=${value}`
          }`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          if (paresData.users.length > 0) {
            setLoadMoreShow(true);
          } else {
            setLoadMoreShow(false);
          }

          setData((prevData) => {
            // Append new users to the existing array of users
            return [...prevData, ...paresData.users];
          });
        } else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  };

  const getSchoolName = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/getSchoolName`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setSchoolName(paresData.schoolList);
        } else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  };

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    getAllData(selectedSchool, currentPage + 1, inputValue);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;

    return `${day}/${month}/${year}`;
  };

  const handleEdit = (item) => {
    setIsEditShow(true);
    setCurrentItem(item);
  };

  const addSuccessfully = () => {
    setAddedName(true);
    setTimeout(() => {
      setAddedName(false);
    }, 1000);
  };

  const removeSuccessfully = () => {
    setAddedName(false);
  };

  const clearItem = () => {
    setIsDeleteShow(false);
    setIsEditShow(false);
    setIsImportShow(false);
  };

  const handleImport = () => {
    setIsImportShow(true);
  };

  const handleDelete = (item) => {
    setIsDeleteShow(true);
    setCurrentItem(item);
  };

  const handleSchoolChange = (event) => {
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    setSelectedSchool(event.target.value);
    getAllData(event.target.value, 0);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setData([]);
    setCurrentPage(0);
    setLoadMoreShow(false);
    getAllData(selectedSchool, currentPage, value);
  };

  return (
    <main>
      <EditStudent
        show={isEditShow}
        onHide={() => setIsEditShow(false)}
        currentItem={currentItem}
        addSuccessfully={addSuccessfully}
        clearItem={clearItem}
      />

      <DeleteStudentDialog
        show={isDeleteShow}
        onHide={() => setIsDeleteShow(false)}
        currentItem={currentItem}
        addSuccessfully={addSuccessfully}
        clearItem={clearItem}
      />

      <FileUploadModal
        show={isImportShow}
        onHide={() => setIsImportShow(false)}
        currentItem={currentItem}
        addSuccessfully={addSuccessfully}
        clearItem={clearItem}
      />

      <div style={{ marginTop: "10px" }}>
        {isError ? (
          <div
            className={`alert alert-danger fade ${isError ? "show" : " "}`}
            role="alert"
          >
            <strong>{error}</strong>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="quiz-box">
        <div className="title">
          Students List{" "}
          <div>
            <Button onClick={handleImport}>
              <i class="fa-solid fa-file-import"></i> Import
            </Button>{" "}
            <Button onClick={()=>exportData(selectedSchool)}>
              <i class="fa-solid fa-download"></i> Export
            </Button>
          </div>
        </div>
        <div className="box-wraper">
          <div className="box">
            <div className="row">
              <div className="card-body">
                <span
                  style={{ display: "flex", gap: "8px", marginLeft: "10px" }}
                >
                  Filter On
                </span>
                <div
                  style={{ display: "flex", gap: "8px", marginLeft: "10px" }}
                >
                  <Chip
                    variant={selectedTab === "All" ? "filled" : "outlined"}
                    label="All"
                    color="success"
                    icon={selectedTab === "All" ? <CheckCircle /> : null}
                    onClick={() => handleTabChange("All")}
                  />
                  <Chip
                    variant={selectedTab === "name" ? "filled" : "outlined"}
                    label="Name"
                    color="success"
                    icon={selectedTab === "name" ? <CheckCircle /> : null}
                    onClick={() => handleTabChange("name")}
                  />
                  <Chip
                    variant={selectedTab === "Class" ? "filled" : "outlined"}
                    label="Class"
                    color="success"
                    icon={selectedTab === "Class" ? <CheckCircle /> : null}
                    onClick={() => handleTabChange("Class")}
                  />
                  <Chip
                    variant={selectedTab === "stream" ? "filled" : "outlined"}
                    label="Stream"
                    color="success"
                    icon={selectedTab === "stream" ? <CheckCircle /> : null}
                    onClick={() => handleTabChange("stream")}
                  />
                  {selectedTab === "All" ? (
                    ""
                  ) : (
                    <div>
                      <TextField
                        value={inputValue}
                        onChange={handleSearchChange}
                        placeholder={`search by ${selectedTab}`}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon color="action" />
                            </InputAdornment>
                          ),
                          endAdornment: inputValue && (
                            <InputAdornment position="end">
                              <CloseIcon
                                onClick={() => handleClear()}
                                style={{ cursor: "pointer", color: "gray" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <select
                    className="form-select"
                    value={selectedSchool}
                    onChange={handleSchoolChange}
                    name="dropdown"
                  >
                    <option value="">-----Filter School------</option>
                    {schoolName &&
                      schoolName.map((item, index) => (
                        <option key={index} value={`${item}`}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <table id="datatablesSimple" className="datatable-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Surname</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Class</th>
                      <th>Stream</th>
                      <th>School Name</th>
                      <th>Password</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataa &&
                      dataa.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.surname}</td>
                          <td>{item.name}</td>
                          <td>{item.userName}</td>
                          <td>{item.Class === "" ? "-" : item.Class}</td>
                          <td>{item.stream === "" ? "-" : item.stream}</td>
                          <td>{item.nameSchool}</td>
                          <td>{item.password}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Button
                                onClick={() => handleEdit(item)}
                                variant="dark"
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </Button>
                              <Button
                                onClick={() => handleDelete(item)}
                                variant="danger"
                              >
                                <i className="fa-solid fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    disabled={!loadmoreShow}
                    variant="primary"
                    onClick={handleNextPage}
                  >
                    Next Page
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
