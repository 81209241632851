import React, { useContext, useEffect, useRef, useState } from "react";
import EditStudent from "./EditStudent";
import { PopupContext } from "../LoginPopupContext";
import { Button } from "react-bootstrap";
import { Margin } from "@mui/icons-material";
import DeleteStudentDialog from "./DeleteStudentDialog";
import FileUploadModal from "./FileUploadModal";
import * as XLSX from 'xlsx';

export default function ResetQuiz() {
  const [loading, setLoading] = useState(false);
  const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

  const initialized = useRef(false);
  const [dataa, setData] = useState();
  const [schoolName, setSchoolName] = useState();
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [testTitle, setTestTitle] = useState();
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectTest, setSelectTest] = useState("");

  const [currentItem, setCurrentItem] = useState();
  const [addedName, setAddedName] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSuccess, setIsSucess] = useState(false);


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getSchoolName();
      getAllTestTitle();
    }
    if (addedName) {
      getSchoolName();
    }
  }, [addedName]);


  const getSchoolName = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/getSchoolName`, requestOptions)
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setSchoolName(paresData.schoolList);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const setMessgae = (msg) => {
    setIsSucess(true);
    setMsg(msg);
    setTimeout(() => {
      setIsSucess(false);
      setMsg("");
    }, 2000);
  };


  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
  };

  const getAllTestTitle = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getAllTestTitle`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          let paresData = await response.json();
          setTestTitle(paresData);
          console.log("paresData-------", paresData);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleTestChange = (event) => {
    setSelectTest(event.target.value);
  };

  const handleReset = () => {
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify({
      nameSchool: selectedSchool,
      testId: selectTest
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/restartTest`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          setMessgae('Reset Successfully.');  
        }else if (response.status === 401) {
          setUnauthorized();
        }
        setLoadingStop();
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  }

  return (
    <main>
      <div style={{ marginTop: "10px" }}>
        {isError ? (
          <div
            className={`alert alert-danger fade ${isError ? "show" : " "}`}
            role="alert"
          >
            <strong>{error}</strong>
          </div>
        ) : (
          ""
        )}
        {isSuccess ? (
            <div
              className={`alert alert-success fade ${isSuccess ? "show" : " "}`}
              role="alert"
            >
              <strong>{msg}</strong>
            </div>
          ) : (
            ""
          )}
      </div>
      <div className="quiz-box">
        <div className="title">Quiz Reset</div>
        <div className="box-wraper">
          <div className="box">
            <div className="mb-3 row">
              <div className="col-sm-11">
                <label className="form-label" htmlFor={`select`}>
                  Select School
                </label>
                <select className="w-100 form-select" value={selectedSchool} onChange={handleSchoolChange} name="dropdown">
                  <option value="">----- select School ------</option>
                  {schoolName && schoolName.map((item, index) => (
                    <option key={index} value={`${item}`}>{item}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="mb-3 row">
              <div className="col-sm-11">
                <label className="form-label" htmlFor={`select`}>
                  Select Quiz
                </label>
                <select className="w-100 form-select" value={selectTest} onChange={handleTestChange} name="dropdown">
                  <option value="" selected>Select a Quiz</option>
                  {testTitle && testTitle.tets.map((item, index) => (
                    <option key={index} value={`${item._id}`}>{item.titleName}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="mb-3 row">
              <div className="col-sm-11">
                <Button variant="danger" onClick={handleReset}>Reset</Button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </main>
  );
}
