import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PopupContext } from "../LoginPopupContext";

export default function SSideNav() {
  const { selectedMenu, setCurrentMenu, clearMenu } = useContext(PopupContext);

  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <div className={`nav-link ${selectedMenu === "Home" ? 'active' : ''}`} onClick={() => setCurrentMenu("Home")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Home
            </div>
            <div className={`nav-link ${selectedMenu === "completeQuiz" ? 'active' : ''}`} onClick={() => setCurrentMenu("completeQuiz")}>
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Complete Quiz
            </div>
          </div>
        </div>
        {/* <div className="sb-sidenav-footer">
          <div className="small">Logged in as:</div>
          Start Bootstrap
        </div> */}
      </nav>
    </div>
  );
}
