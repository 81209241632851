import React, { useContext, useEffect, useRef } from "react";
import { PopupContext } from "../LoginPopupContext";
import TTopNav from "./TTopNav";
import TSideNav from "./TSideNav";
import THome from "./THome";
import TFooter from "./TFooter";
import TestCreate from "./TestCreate";
import TestList from "./TestList";
import TestIndicatorCreate from "./TestIndicatorCreate";

export default function TestDashboard() {
  const { selectedMenu, setCurrentMenu, clearMenu } = useContext(PopupContext);
  const initialized = useRef(false); // Ensures the API call is made only once

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            clearMenu();
        }
    }, []);

  return (
    <body className="sb-nav-fixed">
      <TTopNav />
      <div id="layoutSidenav">
        <TSideNav />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              {/* {selectedMenu === 'Home' ? <THome /> : ''} */}
              {selectedMenu === 'testCre' ? <TestCreate /> : ''}
              {selectedMenu === 'Home' ? <TestList /> : ''}
              {selectedMenu === 'testIndicatorCre' ? <TestIndicatorCreate /> : ''}
            </div>
          </main>
          <TFooter />
        </div>
      </div>
    </body>
  );
}
