import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Chip, Box } from '@mui/material';
import { CancelSharp } from '@mui/icons-material';
import { PopupContext } from './LoginPopupContext';

const AutoCompleteChips = ({ onChipChange }) => {
    const { setUnauthorized } = useContext(PopupContext);
    const [chips, setChips] = useState([]); // Track the chips in state
    const [selectedOption, setSelectedOption] = useState(null); // Track selected option
    const [options, setOptions] = useState([]); // Track options for select dropdown
    const initialized = useRef(false); // Ensures the API call is made only once

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getAllTestTitle();
        }
    }, []);

    // Function to fetch test titles from API
    const getAllTestTitle = () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getAllTestTitle`, requestOptions)
            .then(async (response) => {
                if (response.status === 200) {
                    let parsedData = await response.json();
                    const formattedOptions = parsedData.tets.map(item => ({
                        label: item.titleName, // Display titleName as label
                        value: item._id       // Store _id as value
                    }));

                    setOptions(formattedOptions); // Set options for select dropdown
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Handle selection of an option from the react-select dropdown
    const handleSelectChange = (selected) => {
        if (selected && !chips.includes(selected.value)) {
            const newChips = [...chips, selected.value]; // Only store value (_id)
            setChips(newChips); // Update chips state
            if (onChipChange) {
                onChipChange(newChips); // Notify parent with updated chip list
            }
        }
        setSelectedOption(selected);
    };

    // Handle chip removal
    const handleChipRemove = (chipToRemove) => {
        const newChips = chips.filter((chip) => chip !== chipToRemove); // Remove chip by value (_id)
        setChips(newChips); // Update chips state
        if (onChipChange) {
            onChipChange(newChips); // Notify parent with updated chip list
        }
    };

    return (
        <div className='w-100'>
            <Select
                value={selectedOption}
                onChange={handleSelectChange}
                options={options}
                placeholder="Search Quiz"
                isClearable
            />

            {/* Display Chips using Material UI's Chip Component */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '15px' }}>
                {chips.map((chip, index) => (
                    <Chip
                        key={index}
                        label={options.find(option => option.value === chip)?.label} // Display label based on value
                        onDelete={() => handleChipRemove(chip)} // Remove the chip on delete
                        deleteIcon={
                            <CancelSharp sx={{ color: 'white' }} /> // White color for delete icon
                        }
                        sx={{
                            margin: '5px',
                            backgroundColor: '#555555', // Dark gray background for chip
                            color: '#fff', // White text for the chip
                            cursor: 'pointer',
                        }}
                    />
                ))}
            </Box>
        </div>
    );
};

export default AutoCompleteChips;
