import React, { useContext, useEffect, useRef, useState } from 'react'
import AddTestLogin from './AddTestLogin';
import { Button } from 'react-bootstrap';
import DeleteStudentDialog from './DeleteStudentDialog';
import EditStudent from './EditStudent';
import EditTM from './EditTM';
import { PopupContext } from '../LoginPopupContext';

export default function TestLoginCreate() {
    const initialized = useRef(false);
    const [dataa, setData] = useState();
    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);
    const [addedName, setAddedName] = useState(false);
    const [isEditShow, setIsEditShow] = useState(false);
    const [isAddShow, setIsAddShow] = useState(false);

    const [isDeleteShow, setIsDeleteShow] = useState(false);
    const [currentItem, setCurrentItem] = useState();
    const { setUnauthorized } = useContext(PopupContext);


    const setErrors = (error) => {
        setIsError(true);
        setError(error);
        setTimeout(() => {
            setIsError(false);
            setError("");
        }, 2000);
    };

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getAllData();
        }
        if (addedName) {
            getAllData();
        }
    }, [addedName]);

    const getAllData = () => {
        const myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `Bearer ${sessionStorage.getItem("token")}`
        );

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/users/getAllUsers?roleType=TestManager`,
            requestOptions
        )
            .then(async (response) => {
                if (response.status === 200) {
                    let paresData = await response.json();
                    setData(paresData);
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setErrors(error);
                console.log(error);
            });
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;

        const formattedTime = `${String(hours).padStart(
            2,
            "0"
        )}:${minutes} ${ampm}`;

        return `${day}/${month}/${year}`;
    };

    const addSuccessfully = () => {
        setAddedName(true);
    };

    const clearItem = () => {
        setIsEditShow(false);
        setIsDeleteShow(false);
    };

    const handleEdit = (item) => {
        setAddedName(false);
        setIsEditShow(true);
        setCurrentItem(item);
    }

    const handleDelete = (item) => {
        setAddedName(false);
        setIsDeleteShow(true);
        setCurrentItem(item);
    }

    return (
        <main>
            <AddTestLogin
                show={isAddShow}
                onHide={() => setIsAddShow(false)}
                addSuccessfully={addSuccessfully}
                clearItem={clearItem}
            />

            <EditTM
                show={isEditShow}
                onHide={() => setIsEditShow(false)}
                currentItem={currentItem}
                addSuccessfully={addSuccessfully}
                clearItem={clearItem}
            />

            <DeleteStudentDialog
                show={isDeleteShow}
                onHide={() => setIsDeleteShow(false)}
                currentItem={currentItem}
                addSuccessfully={addSuccessfully}
                clearItem={clearItem}
            />

            <div style={{ marginTop: "10px" }}>
                {isError ? (
                    <div
                        className={`alert alert-danger fade ${isError ? "show" : " "}`}
                        role="alert"
                    >
                        <strong>{error}</strong>
                    </div>
                ) : (
                    ""
                )}
            </div>

            <div className="quiz-box">
                <div className="title">Test Manager Login List <Button onClick={() => setIsAddShow(true)}>Add</Button></div>
                <div className="box-wraper">
                    <div className="box">
                        <div className="row">
                            <div className="card-body">
                                <table id="datatablesSimple" className="datatable-table">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                            <th>Username</th>
                                            <th>Password</th>
                                            <th style={{ textAlign: 'center' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataa &&
                                            dataa.users.map((item, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.userName}</td>
                                                    <td>{item.password}</td>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                            <Button onClick={() => handleEdit(item)} variant="dark">
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </Button>
                                                            <Button onClick={() => handleDelete(item)} variant="danger">
                                                                <i className="fa-solid fa-trash"></i>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
