import React, { useContext, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { PopupContext } from '../LoginPopupContext';

function FileUploadModal(props) {
    const [file, setFile] = useState(null);
    const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);

    const handleEdit = () => {
        props.addSuccessfully();
        props.onHide();
        props.clearItem();
      };

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Handle file upload
    const handleUpload = () => {
        if (!file) {
            console.error("Please select a file to upload.");
            return;
        }
        setLoadingStart();
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${sessionStorage.getItem("token")}`
        );
    
        const formData = new FormData();
        formData.append("file", file);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/createUserByExcel`, requestOptions)
        .then(async (response) => {
            if (response.status === 200) {
              handleEdit();
            }else if (response.status === 401) {
                setUnauthorized();
              }
            setLoadingStop();
          })
          .catch((error) => {
            setLoadingStop();
            setErrors(error);
            console.log(error);
          });
    };

    const setErrors = (error) => {
        setIsError(true);
        setError(error);
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 2000);
      };

    return (
        <div>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Excel File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Select Excel File</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} accept=".xlsx" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpload}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FileUploadModal;
