import React, { useContext, useEffect, useRef, useState } from 'react'
import { ReactQuillWrapper } from '../ReactQuillWrapper';
import MyComponent from '../testManager/MyComponent';
import ConfirmationDialog from './ConfirmationDialog';
import { PopupContext } from '../LoginPopupContext';

export default function SHome() {
  const initialized = useRef(false);
  const [apiDATA, setApiData] = useState("");
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [currentTest, setCurrentTest] = useState("");
  const [addedName, setAddedName] = useState(false);

  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSuccess, setIsSucess] = useState(false);
  const { setUnauthorized } = useContext(PopupContext);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callPendingTest();
    }
    if (addedName) {
      callPendingTest();
    }
  }, [addedName]);

  const handleStartQuiz = (id) => {
    setCurrentTest(id);
    setIsConfirmation(true);
  }

  const callPendingTest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "studentId": sessionStorage.getItem("userId"),
      "testStatus": "Pending"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/showTest`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        if (response.status === 200) {
          setApiData(paresData);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setErrors(error);
        console.log(error);
      });
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const onhide = () => {
    setCurrentTest('');
    setIsConfirmation(false);
  }

  const addSuccessfully = () => {
    setAddedName(true);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  };

  return (
    <>
      <ConfirmationDialog
        show={isConfirmation}
        onHide={() => onhide()}
        testId={currentTest}
        addSuccessfully={addSuccessfully} />

      <div style={{ marginTop: "15px" }}>
        {isError ? <div
          className={`alert alert-danger fade ${isError ? "show" : " "}`}
          role="alert"
        >
          <strong>{error}</strong>
        </div> : ''}

        {isSuccess ? <div
          className={`alert alert-success fade ${isSuccess ? "show" : " "}`}
          role="alert"
        >
          <strong>{msg}</strong>
        </div> : ''}
      </div>
      <div className="quiz-box">
        <div className="title">Pending Quiz</div>
        <div className="box-wraper">
          {
            apiDATA &&
              apiDATA.tests && apiDATA.tests.length > 0 ? (
              apiDATA.tests.map((item, index) => (
                <div key={index} className="box">
                  <div className="mb-3 row">
                    <div><label className="col-sm-1 form-label">Quiz No:{index + 1}</label></div>
                    <div className="col-sm-11">
                      <label className="w-100 form-label" htmlFor={`${item.testId && item.testId.titleName}`}>{`Quiz Name: ${item.testId && item.testId.titleName}`}</label>
                      <label className="w-100 form-label" htmlFor={`${item.testId && item.testId.quizTime}`}><MyComponent apiData={item.testId && item.testId.quizTime} /></label>
                      <button className="btn btn-success" onClick={() => handleStartQuiz(item.testId._id)}>Start Quiz</button>
                    </div>
                    <label className="w-100" htmlFor={`${item && item.allocateTime}`}>Allocated Time :- {formatDate(item.allocateTime)}</label>
                  </div>
                </div>
              ))) : (
              <div>
                Quiz not found!!!
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}
