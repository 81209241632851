import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { modules, formats, noImageformats, noImagemodules } from '../../testManager/Utils';
import ReactQuill from "react-quill";
import { PopupContext } from "../../LoginPopupContext";


function EditInstruction(props) {
    const nav = useNavigate();
    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);
    const [instruction, setInstruction] = useState('');
    const { setUnauthorized } = useContext(PopupContext);

    useEffect(() => {
        if (props && props.testInfo) {
            setInstruction(props.testInfo.titleName);
        }
    }, [props]);

    const handleEdit = () => {
        handleUpdate();
    };

    const handleUpdate = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
            id:props.testId,
            instruction:instruction
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/updateTest`, requestOptions)
            .then(async (response) => {
                if (response.status === 200) {
                    props.addSuccessfully();
                    props.onHide();
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setErrors(error);
                console.log(error);
            });
    }

    const setErrors = (error) => {
        setIsError(true);
        setError(error);
        setTimeout(() => {
            setIsError(false);
            setError("");
        }, 2000);
    };

    const handleInstruction = (value) => {
        setInstruction(value);
    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update Quiz Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="box">
                        <div className="w-100">
                            <div className="w-100">
                                <ReactQuill
                                    value={instruction}
                                    modules={noImagemodules}
                                    formats={noImageformats}
                                    onChange={handleInstruction}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditInstruction;
