import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  modules,
  formats,
  noImageformats,
  noImagemodules,
} from "../../testManager/Utils";
import ReactQuill from "react-quill";
import { PopupContext } from "../../LoginPopupContext";

function EditInfo(props) {
  const nav = useNavigate();
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [time, setTime] = useState("");
  const [timeMinute, setTimeMinute] = useState("");
  const [queTitle, setQueTitle] = useState("");
  const [totalQueShow, setTotalQueShow] = useState("");
  const { setUnauthorized } = useContext(PopupContext);

  useEffect(() => {
    if (props && props.testInfo) {
      setQueTitle(props.testInfo.titleName);
      setTotalQueShow(props.testInfo.totalQue);
      setTime(props.testInfo.quizTime);
      setTimeMinute(props.testInfo.quizDuration);
    }
  }, [props]);

  const handleEdit = () => {
    handleUpdate();
  };

  const handleUpdate = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const raw = JSON.stringify({
      id: props.testId,
      titleName: queTitle,
      totalQue: totalQueShow,
      quizDuration:timeMinute,
      quizTime: time,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/test/updateTest`,
      requestOptions
    )
      .then(async (response) => {
        if (response.status === 200) {
          props.addSuccessfully();
          props.onHide();
        } else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setErrors(error);
        console.log(error);
      });
  };

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const handleTime = (value) => {
    setTime(value);
  };

  const handleTimeMinute = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setTimeMinute(inputValue);
    }
  };

  const handleTotalShowQue = (value) => {
    setTotalQueShow(value);
  };

  const handleTitle = (event) => {
    setQueTitle(event.target.value);
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Quiz Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box">
            <div className="mb-3 row">
              <label>
                <strong>Quiz Title</strong>
              </label>
              <div className="col-sm-11">
                <input
                  type="text"
                  className="form-control"
                  value={queTitle}
                  onChange={handleTitle}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label>
                <strong>Total Questions in Word</strong>
              </label>
              <div className="col-sm-11">
                <ReactQuill
                  value={totalQueShow}
                  modules={noImagemodules}
                  formats={noImageformats}
                  onChange={handleTotalShowQue}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label>
                <strong>Quiz Time in Word</strong>
              </label>
              <div className="col-sm-11">
                <ReactQuill
                  value={time}
                  modules={noImagemodules}
                  formats={noImageformats}
                  onChange={handleTime}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label>
                <strong>Quiz Time in Minute</strong>
              </label>
              <div className="col-sm-11">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Minutes"
                  value={timeMinute}
                  onChange={handleTimeMinute}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditInfo;
