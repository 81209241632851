import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { modules, formats, noImageformats, noImagemodules } from '../../testManager/Utils';
import ReactQuill from "react-quill";
import { PopupContext } from "../../LoginPopupContext";


function EditMBTIQuestions(props) {
    const nav = useNavigate();
    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false);
    const [questionData, setQuestionData] = useState();
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState('');
    const [answer, setAnswer] = useState('');
    const { setUnauthorized } = useContext(PopupContext);

    useEffect(() => {
        if (props && props.currentQuestion) {
            setQuestionData(props.currentQuestion);
            setQuestion(props.currentQuestion.question);
            setOptions(props.currentQuestion.option);
            setAnswer(props.currentQuestion.ansId);
        }
    }, [props]);

    const handleEdit = () => {
        handleUpdate();
    };

    const handleAns = (event) => {
        setAnswer([event.target.value]);
    };

    const handleUpdate = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
            testId: props.testId,
            queId: props.currentQuestion.id,
            question: question,
            ansId: answer,
            option: options
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/updateTestQue`, requestOptions)
            .then(async (response) => {
                if (response.status === 200) {
                    props.addSuccessfully();
                    props.onHide();
                }else if (response.status === 401) {
                    setUnauthorized();
                  }
            })
            .catch((error) => {
                setErrors(error);
                console.log(error);
            });
    }

    const setErrors = (error) => {
        setIsError(true);
        setError(error);
        setTimeout(() => {
            setIsError(false);
            setError("");
        }, 2000);
    };

    const handleQues = (value) => {
        setQuestion(value);
    };

    // Handler to dynamically add a new option
    const addOption = () => {
        setOptions((prevOptions) => [
            ...prevOptions,
            { id: prevOptions.length, title: "" } // Default title for a new option
        ]);
    };

    // Handler to dynamically remove an option
    const removeOption = (id) => {
        setOptions((prevOptions) => prevOptions.filter((opt) => opt.id !== id));
    };

    // Handler to update an option's title
    const updateOptionTitle = (id, title) => {
        setOptions((prevOptions) =>
            prevOptions.map((opt) =>
                opt.id === id ? { ...opt, title } : opt
            )
        );
    };

    const updateOptionIndicator = (id, indicator) => {
        setOptions((prevOptions) =>
            prevOptions.map((opt) =>
                opt.id === id ? { ...opt, indicator } : opt
            )
        );
    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update Quiz Information</Modal.Title>
                </Modal.Header>
                {props && props.currentQuestion &&

                    <Modal.Body>
                        <div className="box">
                            <div className="mb-3 row">
                                <label className="col-sm-1 form-label">Question</label>
                                <div className="col-sm">
                                    <ReactQuill
                                        value={question || ''}
                                        modules={modules}
                                        formats={formats}
                                        onChange={handleQues}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-1 form-label align-items-start">Options</label>
                                <div className="col-sm-11">

                                    {options && options.map((opt, optIndex) => (
                                        <div key={optIndex} style={{ marginBottom: "5px" }}>
                                            <input
                                                type="text"
                                                value={opt.title}
                                                className='form-controlll'
                                                placeholder={`Option ${optIndex +1}`}
                                                onChange={(e) => updateOptionTitle(opt.id, e.target.value)}
                                            />
                                             <input
                                                type="text"
                                                value={opt.indicator}
                                                className='form-controlll'
                                                style={{marginLeft: "10px"}}
                                                placeholder={`Indicator ${optIndex +1}`}
                                                onChange={(e) => updateOptionIndicator(opt.id, e.target.value)}
                                            />
                                            <button style={{ background: "none", border: "none", marginLeft: "5px", fontSize: '22px' }} onClick={() => removeOption(opt.id)}><i class="fa-solid fa-trash-can" style={{ color: "#ff0000" }}></i></button>
                                        </div>
                                    ))}
                                    <Button variant="secondary" onClick={addOption}>Add Option</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditMBTIQuestions;
