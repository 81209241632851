import React, { useContext } from "react";
import { PopupContext } from "../LoginPopupContext";
import STopNav from "./STopNav";
import SSideNav from "./SSideNav";
import SHome from "./SHome";
import SFooter from "./SFooter";
import CompleteQuiz from "./CompleteQuiz";

export default function StudentDashboard() {
  const { selectedMenu, setCurrentMenu, clearMenu } = useContext(PopupContext);
  return (
    <body className="sb-nav-fixed">
      <STopNav />
      <div id="layoutSidenav">
        <SSideNav />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4">
              {selectedMenu === 'Home' ? <SHome /> : ''}
              {selectedMenu === 'completeQuiz' ? <CompleteQuiz /> : ''}

            </div>
          </main>
          <SFooter />
        </div>
      </div>
    </body>
  );
}
