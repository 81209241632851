import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PopupContext } from "../../LoginPopupContext";

function DeleteQue(props) {
  const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (props && props.currentItem) {
    }
  }, [props]);

  const handleEdit = () => {
    props.addSuccessfully();
    props.onHide();
    props.clearItem();
  };

  const handleUpdate = () => {
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify({
      testId: props.testId,
      queId: props.queId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/deleteTestQue`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          handleEdit();
        }else if (response.status === 401) {
          setUnauthorized();
        }
        setLoadingStop();
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="surname.ControlInput1">
                  <Form.Label>Are you sure you want to delete this Question?</Form.Label>
                </Form.Group>
              </Col>

            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="danger" onClick={handleUpdate}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteQue;
