import React, { useContext, useEffect, useRef, useState } from 'react'
import { PopupContext } from '../LoginPopupContext';
import AutoCompleteChips from '../AutoCompleteChips';

export default function TestAllocate() {
  const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

  const initialized = useRef(false);
  const [testTitle, setTestTitle] = useState();
  const [dataa, setData] = useState();
  const [checkedIds, setCheckedIds] = useState([]);
  const [selectTest, setSelectTest] = useState("");
  const [selectedChips, setSelectedChips] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [schoolName, setSchoolName] = useState();

  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleTestChange = (event) => {
    setSelectTest(event.target.value);
  };

  const handleChipChange = (newChips) => {
    console.log('Updated Chips Array:', newChips);
    setSelectedChips(newChips); // Update the selected chips in the parent
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // for a smooth scrolling effect
    });
  };

  const reset = () => {
    setCheckedIds([]);
    scrollToTop();
  }

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (checked) {
      setCheckedIds((prevIds) => [...prevIds, id]);
    } else {
      setCheckedIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
    }
  };

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const setSuccess = (msg) => {
    setIsSuccess(true);
    setMsg(msg);
    setTimeout(() => {
      setIsSuccess(false);
      setMsg("");
    }, 2000);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getAllTestTitle();
      getAllData("");
      getSchoolName();
    }
  }, []);

  const getAllTestTitle = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getAllTestTitle`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          let paresData = await response.json();
          setTestTitle(paresData);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getAllData = (selectedSchool) => {
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      selectedSchool === '' ? `${process.env.REACT_APP_BASE_URL}/api/v1/users/getAllUserList?roleType=Student`
        :
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/getAllUserList?roleType=Student&nameSchool=${selectedSchool}`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setData(paresData);
          reset();
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        console.log(error);
      });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;

    return `${day}/${month}/${year}`;
  };

  const handleClick = () => {
    if (selectedChips.length !== 0 ) {
      const data = {
        "studentIds": checkedIds,
        "testIds": selectedChips
      }
      callAllocate(data);
    } else {
      setErrors("Please select quiz.");
      reset();
    }
  }

  const callAllocate = (data) => {
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify(data);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/testAllocate`, requestOptions)
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setSuccess("Quiz Allocated successfully.");
          getAllData(selectedSchool);
          reset();
        }else if (response.status === 401) {
          setUnauthorized();
        } else {
          reset();
          let paresData = await response.json();
          setErrors(paresData.message);
        }
      })
      .catch((error) => {
        setLoadingStop();
        reset();
        setErrors(error);
        console.log(error);
      });
  }

  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
    getAllData(event.target.value);
  };

  const getSchoolName = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/getSchoolName`, requestOptions)
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setSchoolName(paresData.schoolList);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  }

  const handleSelectAll = (event) => {

    const { checked } = event.target;
    if (checked) {
      // Select all checkboxes
      const allIds = dataa.users.map(user => user._id);
      setCheckedIds(allIds);
    } else {
      // Deselect all checkboxes
      setCheckedIds([]);
    }
  };

  return (
    <div style={{ marginTop: "15px" }}>
      {isError ? <div
        className={`alert alert-danger fade ${isError ? "show" : " "}`}
        role="alert"
      >
        <strong>{error}</strong>
      </div> : ''}

      {isSuccess ? <div
        className={`alert alert-success fade ${isSuccess ? "show" : " "}`}
        role="alert"
      >
        <strong>{msg}</strong>
      </div> : ''}
      <div className="quiz-box">
        <div className="title">Quiz Allocation</div>
        <div className="box-wraper">
          <div className="box">
            <div className="mb-3 row">
              <div className="col-sm-11">
                <label className="form-label" htmlFor={`select`}>
                  Select Quiz
                </label>
                <AutoCompleteChips className='w-100' onChipChange={handleChipChange} />
              </div>
            </div>
          </div>

          <div className="box">
            <div className="container-fluid px-4">
              <div className="row">
                <div className="card-body">

                  <div style={{ marginBottom: "10px" }}>

                    <select className="form-select" value={selectedSchool} onChange={handleSchoolChange} name="dropdown">
                      <option value="" selected>-----Filter School------</option>
                      {schoolName && schoolName.map((item, index) => (
                        <option key={index} selected={item === selectedSchool} value={`${item}`}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <table id="datatablesSimple" className="datatable-table">
                    <thead>
                      <tr>
                        <th>{dataa && <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={handleSelectAll}
                          checked={checkedIds.length === dataa.users.length}
                        />}</th>
                        <th>No.</th>
                        <th>Surname</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Class</th>
                        <th>Stream</th>
                        <th>School Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataa &&
                        dataa.users.map((item, index) => (
                          <tr>
                            <td><input
                              type="checkbox"
                              className="form-check-input"
                              checked={checkedIds.includes(`${item._id}`)}
                              onChange={handleCheckboxChange}
                              id={`${item._id}`}
                            /></td>
                            <td>{index + 1}</td>
                            <td>{item.surname}</td>
                            <td>{item.name}</td>
                            <td>{item.userName}</td>
                            <td>{item.Class}</td>
                            <td>{item.stream !=="" ? item.stream:'-'}</td>
                            <td>{item.nameSchool}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <button className="btn btn-success" onClick={handleClick}>Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
