import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../LoginPopupContext";

function ConfirmationDialog(props) {
  const nav = useNavigate();
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const { setUnauthorized } = useContext(PopupContext);

  useEffect(() => {
    if (props && props.testId) {
    }
  }, [props]);

  const handleEdit = () => {
    handleUpdate();
  };

  const handleUpdate = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "studentId": sessionStorage.getItem("userId"),
      "testId": props.testId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/startTest`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          props.addSuccessfully();
          props.onHide();
          nav(`/quizStarted/${props.testId}`);
        }else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setErrors(error);
        console.log(error);
      });
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm to start Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="surname.ControlInput1">
                  <Form.Label>Once you press the "Start Quiz" button, the quiz timer will begin. You must complete and submit the quiz to ensure your attempt is recorded. If you leave without completing the quiz, your session will be dismissed, and you won't be able to restart. Please ensure you're ready before starting.</Form.Label>
                  <Form.Label>जैसे ही आप स्टार्ट बटन दबाते हैं, टेस्ट का समय शुरू हो जाएगा। आपको टेस्ट पूरा करके सबमिट करना अनिवार्य है ताकि आपका टेस्ट रिकॉर्ड हो सके। यदि आप बिना पूरा किए बाहर जाते हैं, तो आपका टेस्ट रद्द हो जाएगा और आप दोबारा टेस्ट शुरू नहीं कर पाएंगे। कृपया सुनिश्चित करें कि आप शुरू करने के लिए तैयार हैं।</Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Start Quiz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationDialog;
