import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../LoginPopupContext";

function StartedQuizDialog(props) {
  const nav = useNavigate();

  useEffect(() => {
    if (props && props.testId) {
    }
  }, [props]);

  const handleBack = () => {
    props.onHide();
    nav(`/studentDashboard`, { replace: true });
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Quiz Not Started</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="surname.ControlInput1">
                  <Form.Label>Your quiz is not Started.</Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleBack}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StartedQuizDialog;
