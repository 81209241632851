import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PopupContext } from "../../LoginPopupContext";
import { CheckCircle } from '@mui/icons-material';
import { Chip } from '@mui/material';

function ExportFile(props) {
    const { setLoadingStart, setLoadingStop, setUnauthorized } = useContext(PopupContext);
    const [selectedTab, setSelectedTab] = useState("All"); // Default active tab
    const [selectedSchool, setSelectedSchool] = useState('');

    const [schoolName, setSchoolName] = useState();
    const [fromDate, setFromDate] = useState(null);

    useEffect(() => {
        getSchoolName();
    }, [props]);

    const handleSchoolChange = (event) => {
        setSelectedSchool(event.target.value);
    };

    const getSchoolName = () => {
        const myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `Bearer ${sessionStorage.getItem("token")}`
        );

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/getSchoolName`, requestOptions)
            .then(async (response) => {
                if (response.status === 200) {
                    let paresData = await response.json();
                    setSchoolName(paresData.schoolList);
                } else if (response.status === 401) {
                    setUnauthorized();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const formatDate = (date) => {
        if (!date) return "";
        return date.toLocaleDateString("en-CA");
    };

    const handleDownload = () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
        myHeaders.append("Accept", 'text/csv');

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/getreportexcelfile${selectedTab !== 'All' ? `?${selectedTab}=${selectedTab === 'date' ? `${formatDate(fromDate)}` : selectedSchool}` : ''}`, requestOptions)
            .then(async (response) => {
                if (response.status === 200) {
                    // Check if the response content is gzip-compressed
                    const contentEncoding = response.headers.get('Content-Encoding');
                    let data = await response.blob();

                    // If the response is gzip-compressed, we need to decompress it (optional, depends on the API)
                    if (contentEncoding === 'gzip') {
                        const arrayBuffer = await data.arrayBuffer();
                        const decompressedData = new TextDecoder().decode(new Uint8Array(arrayBuffer));
                        data = new Blob([decompressedData], { type: 'text/csv' });
                    }

                    // Create a URL for the blob object and trigger the download
                    const blobUrl = URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = blobUrl;

                    // Optional: Get the filename from the content-disposition header or provide a default one
                    const contentDisposition = response.headers.get('Content-Disposition');
                    let filename = `StudentReportData_${formatDate(fromDate)}.csv`;
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="([^"]+)"/);
                        if (match) {
                            filename = match[1];
                        }
                    }

                    // Set the filename and trigger the download
                    link.download = filename;
                    link.click();

                    // Clean up the URL object after the download
                    URL.revokeObjectURL(blobUrl);
                    props.onHide();
                } else if (response.status === 401) {
                    props.onHide();
                    setUnauthorized();
                }
            })
            .catch((error) => {
                props.onHide();
                console.log(error);
            });
    }

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <Form.Control
            className="form-control"
            style={{ cursor: "pointer" }}
            onClick={onClick}
            value={value}
            ref={ref}
            readOnly
            placeholder="dd-MM-yyyy"
        />
    ));

    const handleTabChange = (tabName) => {
        setSelectedTab(tabName);
    };


    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <div className="card-body">
                                <div style={{ display: "flex", gap: "8px", marginLeft: '10px', marginBottom: '10px' }}>
                                    <Chip
                                        variant={selectedTab === "All" ? "filled" : "outlined"}
                                        label="All Report"
                                        color="success"
                                        icon={selectedTab === "All" ? <CheckCircle /> : null}
                                        onClick={() => handleTabChange("All")}
                                    />
                                    <Chip
                                        variant={selectedTab === "date" ? "filled" : "outlined"}
                                        label="Selected date Report"
                                        color="success"
                                        icon={selectedTab === "date" ? <CheckCircle /> : null}
                                        onClick={() => handleTabChange("date")}
                                    />
                                    <Chip
                                        variant={selectedTab === "nameSchool" ? "filled" : "outlined"}
                                        label="School Report"
                                        color="success"
                                        icon={selectedTab === "nameSchool" ? <CheckCircle /> : null}
                                        onClick={() => handleTabChange("nameSchool")}
                                    />
                                </div>
                            </div>
                            <Col>
                                {selectedTab === "nameSchool" ?
                                    <div style={{ marginBottom: "10px", marginTop: "10px", marginLeft: '10px' }}>
                                        <select className="form-select" value={selectedSchool} onChange={handleSchoolChange} name="dropdown">
                                            <option value="">-----Filter School------</option>
                                            {schoolName && schoolName.map((item, index) => (
                                                <option key={index} value={`${item}`}>{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                    : ""}

                                {selectedTab === "date" ?
                                    <Form.Group controlId="surname.ControlInput1">
                                        <Form.Label>Select Date:</Form.Label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => {
                                                setFromDate(date);
                                            }}
                                            dateFormat='dd-MM-yyyy'
                                            customInput={<CustomInput />}
                                            maxDate={new Date()}
                                        />
                                    </Form.Group> : ''}
                            </Col>

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDownload}>
                        Export
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ExportFile;
