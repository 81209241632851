import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PopupContext } from "../LoginPopupContext";

function EditStudent(props) {
  const { setLoadingStart, setLoadingStop ,setUnauthorized} = useContext(PopupContext);

  const [selectClass, setSelectClass] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNo] = useState("");
  const [stream, setStream] = useState("");
  const [password, setPassword] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  const formattedDate = selectedDate;
  const max = new Date();

  useEffect(() => {
    if (props && props.currentItem) {
      console.log(props.currentItem, "- CURRENT ITEM");
      setName(props.currentItem.name);
      setEmail(props.currentItem.email);
      setMobileNo(props.currentItem.mobileNumber);
      setStream(props.currentItem.stream);
      setPassword(props.currentItem.password);
      setSchoolName(props.currentItem.nameSchool)
      setSelectClass(props.currentItem.Class)
    }
  }, [props]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSchoolChange = (e) => {
    setSchoolName(e.target.value);
  };
  const handleStreamChange = (e) => {
    setStream(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleContactChange = (e) => {
    setMobileNo(e.target.value);
  };
  const handleClassChange = (event) => {
    setSelectClass(event.target.value);
  };

  const handleEdit = () => {
    props.addSuccessfully();
    props.onHide();
    props.clearItem();
  };

  const handleUpdate = () => {
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "id": `${props.currentItem._id}`,
      "Class": `${selectClass}`,
      "stream": `${stream}`,
      "contact": `${mobileNumber}`,
      "email": `${email}`,
      "nameSchool": `${schoolName}`,
      "password": `${password}`
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/editUser`, requestOptions)
    .then(async (response) => {
      if (response.status === 200) {
        handleEdit();
      }else if (response.status === 401) {
        setUnauthorized();
      }
      setLoadingStop();
    })
    .catch((error) => {
      setLoadingStop();
      setErrors(error);
      console.log(error);
    });
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Form.Control
      className="form-control"
      style={{ cursor: "pointer" }}
      onClick={onClick}
      value={value}
      ref={ref}
      readOnly
      placeholder="dd-MM-yyyy"
    />
  ));

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="surname.ControlInput1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ex. Khandala Dhaval V."
                    value={name}
                    readOnly
                  />
                </Form.Group>
              </Col>

            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="schoolName.ControlInput1">
                  <Form.Label>School Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ex. J.N.V."
                    value={schoolName}
                    onChange={handleSchoolChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="6">
                <Form.Group controlId="email.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="mobile.ControlInput1">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ex. 7600656451"
                    value={mobileNumber}
                    onChange={handleContactChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="6">
                <Form.Group controlId="class.ControlSelect1">
                  <Form.Label>Class</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ex. 8, 9, 10 etc..."
                    value={selectClass}
                    onChange={(e)=>setSelectClass(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="stream.ControlInput1">
                  <Form.Label>Stream</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ex. Maths, Science etc..."
                    value={stream}
                    onChange={handleStreamChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="password.ControlInput1">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="******"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditStudent;
