import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import { PopupContext } from "../LoginPopupContext";

export default function CreateStudent() {
  const { setUnauthorized } = useContext(PopupContext);
  const [selectClass, setSelectClass] = useState("");
  const [selectGender, setSelectGender] = useState("Male");
  const [schoolName, setSchoolName] = useState("");
  const [surName, setSurname] = useState("");
  const [sName, setSname] = useState("");
  const [fName, setFname] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNo] = useState("");
  const [stream, setStream] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSuccess, setIsSucess] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const handleSchoolChange = (e) => {
    setSchoolName(e.target.value);
  };
  const handleStreamChange = (e) => {
    setStream(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleContactChange = (e) => {
    setMobileNo(e.target.value);
  };
  const handleSurnameChange = (e) => {
    setSurname(e.target.value);
  };
  const handlesnameChange = (e) => {
    setSname(e.target.value);
  };
  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };

  const handleAgeChange = (e) => {
    setAge(e.target.value);
  };

  const clearForm = () => {
    setSelectClass("");
    setSelectGender("");
    setSchoolName("");
    setSurname("");
    setSname("");
    setFname("");
    setEmail("");
    setMobileNo("");
    setStream("");
    setUsername("");
    setPassword("");
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const formattedDate = selectedDate;
  const max = new Date();

  const handleClassChange = (event) => {
    setSelectClass(event.target.value);
  };
  const handleGenderChange = (event) => {
    setSelectGender(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSaveStudent = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );

    const raw = JSON.stringify({
      name: `${sName}`,
      surname:`${surName}`,
      fname:`${fName}`,
      email: `${email}`,
      Class: `${selectClass}`,
      stream: `${stream}`,
      age: `${age}`,
      gender: `${selectGender}`,
      nameSchool: `${schoolName}`,
      roleType: "Student",
      password: `${password}`,
      contact: `${mobileNumber}`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/createUser`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          setMessgae("Successfully add new student");
          clearForm();
        }else if (response.status === 401) {
          setUnauthorized();
        } else {
          const errorData = await response.json();
          setErrors(errorData.message || "An unexpected error occurred"); // Fallback if no message
        }
      })
      .catch((error) => {
        setErrors(error);
        console.log(error);
      });
  };

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const setMessgae = (msg) => {
    setIsSucess(true);
    setMsg(msg);
    setTimeout(() => {
      setIsSucess(false);
      setMsg("");
    }, 2000);
  };

  return (
    <>
      <main>
        <div style={{ marginTop: "10px" }}>
          {isError ? (
            <div
              className={`alert alert-danger fade ${isError ? "show" : " "}`}
              role="alert"
            >
              <strong>{error}</strong>
            </div>
          ) : (
            ""
          )}
          {isSuccess ? (
            <div
              className={`alert alert-success fade ${isSuccess ? "show" : " "}`}
              role="alert"
            >
              <strong>{msg}</strong>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="quiz-box">
          <div className="title">Add New Student</div>
          <div className="box-wraper">
          <div className="box">
          <div className="row">
            <Form>
              <Row className="mb-3">
                <Col md="4">
                  <Form.Group controlId="surname.ControlInput1">
                    <Form.Label>Surname*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. Khandala"
                      autocomplete="off"
                      value={surName}
                      onChange={handleSurnameChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="sname.ControlInput1">
                    <Form.Label>Student Name*</Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      placeholder="Ex. Dhaval"
                      value={sName}
                      onChange={handlesnameChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="fname.ControlInput1">
                    <Form.Label>Father Name</Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      placeholder="Ex. Vishnubhai"
                      value={fName}
                      onChange={handleFnameChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="4">
                  <Form.Group controlId="exampleForm.Age">
                    <Form.Label>Age</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. 18"
                      autocomplete="off"
                      value={age}
                      onChange={handleAgeChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="sname.ControlInput1">
                    <Form.Label>Gender*</Form.Label>
                    <Form.Select
                      value={selectGender}
                      onChange={handleGenderChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="fname.ControlInput1">
                    <Form.Label>School Name*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. J.N.V."
                      autocomplete="off"
                      value={schoolName}
                      onChange={handleSchoolChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autocomplete="off"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. 7600656451"
                      autocomplete="off"
                      value={mobileNumber}
                      onChange={handleContactChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Class</Form.Label>
                    <Form.Select
                      value={selectClass}
                      onChange={handleClassChange}
                    >
                      <option>Select Class</option>
                      <option value="8th">8th</option>
                      <option value="9th">9th</option>
                      <option value="10th">10th</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Stream</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. Maths, Science etc..."
                      autocomplete="off"
                      value={stream}
                      onChange={handleStreamChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="******"
                      minLength={8}
                      autocomplete="off"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="d-flex justify-content-center">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Button variant="primary" onClick={handleSaveStudent}>
                      Save Student
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
