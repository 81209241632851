import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MyComponent from "../testManager/MyComponent";
import { Button } from "react-bootstrap";
import { PopupContext } from "../LoginPopupContext";

export default function AdminTestView() {
  const { id } = useParams();
  const { stdid } = useParams();
  const initialized = useRef(false);
  const [apiData, setApiData] = useState();
  const nav = useNavigate();
  const { setLoadingStart, setLoadingStop, setUnauthorized } =
    useContext(PopupContext);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callGetTest();
    }
  }, []);

  const handleBack = () => {
    nav(-1);
  };

  const callGetTest = () => {
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/test/showResult?testId=${id}&studentId=${stdid}`,
      requestOptions
    )
      .then(async (response) => {
        setLoadingStop();
        if (response.status === 200) {
          let paresData = await response.json();
          setApiData(paresData);
          console.log("paresData-------", paresData);
        } else if (response.status === 401) {
          setUnauthorized();
        }
      })
      .catch((error) => {
        setLoadingStop();
        console.log(error);
      });
  };

  const pairs = [
    ["E", "I"],
    ["S", "N"],
    ["T", "F"],
    ["J", "P"],
  ];

  function getHighestIndicatorsWithValues(response, pairs) {
    // Map the response data for quick lookup by name
    const valueMap = response.reduce((map, item) => {
      map[item.name] = item.value;
      return map;
    }, {});

    // Compare values in each pair and choose the one with the higher value
    return pairs.map(([first, second]) => {
      const higherIndicator =
        valueMap[first] >= valueMap[second] ? first : second;
      return { name: higherIndicator, value: valueMap[higherIndicator] };
    });
  }

  return (
    <div className="quiz-box">
      <div className="title">
        Name: {apiData?.test?.studentId?.name}
        <p>
          Marks:{" "}
          {apiData?.test?.testId?.type === "advance" &&
          Array.isArray(apiData?.test?.indiResult) &&
          apiData?.test?.indiResult.length > 0
            ? getHighestIndicatorsWithValues(
                apiData.test.indiResult,
                pairs
              ).map((resItem, indexz) => (
                <span key={indexz}>
                  <b>{resItem.name}:</b> {resItem.value}
                  {indexz !== 3 && ", "}
                </span>
              ))
            : apiData?.test?.result}
        </p>
      </div>

      <div className="box-wraper">
        {apiData && apiData.test ? (
          <>
            <div className="box">
              <div className="mb-3 row">
                <div className="col-sm-12">
                  <label
                    className="w-100 form-label justify-content-center"
                    htmlFor={apiData.test.testId.titleName}
                  >
                    <strong>
                      <h2>{apiData.test.testId.titleName}</h2>
                    </strong>
                  </label>
                </div>
              </div>

              <div className="mb-3 row">
                <div className="col-sm-6 d-flex">
                  <div className="col-sm-11">
                    <label
                      className="w-100 form-label"
                      htmlFor={apiData.test.testId.quizTime}
                    >
                      <MyComponent apiData={apiData.test.testId.quizTime} />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {apiData.test.queAns.map((item, index) => (
              <>
                <div className="title">Questions {index + 1}</div>
                <div key={index} className="box">
                  <div className="mb-3 row">
                    <div className="w-100">
                      {item.stdAns.length === item.ansId.length &&
                      item.stdAns.every((ans) => item.ansId.includes(ans)) ? (
                        <i
                          class="fa-solid fa-circle-check"
                          style={{ color: "#00ff33" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-circle-xmark"
                          style={{ color: "#ff0000" }}
                        ></i>
                      )}
                      <label
                        className="w-100 form-label"
                        htmlFor={item.question}
                      >
                        <MyComponent apiData={item.question} />
                      </label>
                    </div>
                  </div>

                  <div class="mb-3 row">
                    <label class="col-sm-1 form-label align-items-start">
                      Options
                    </label>
                    <div class="col-sm-11 flex-wrap align-items-start pt-1">
                      {item.option.map((itemOption, indexs) => (
                        <div key={indexs} class="form-check w-100">
                          <input
                            class="form-check-input"
                            type="radio"
                            checked={item.stdAns[0] == indexs}
                            readOnly
                            name={`flexRadioDefault${index}`}
                            id={`flexRadioDefault${index}`}
                          />
                          <label
                            class="form-check-label"
                            for={`flexRadioDefault${index}`}
                          >
                            {indexs + 1}. {itemOption.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          "Data Not Found!!!"
        )}
        <Button onClick={() => handleBack()}>Back</Button>
      </div>
    </div>
  );
}
