import React, { useState } from 'react';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ResizeModule from "@botom/quill-resize-module";
import { modules, formats } from './testManager/Utils';
import ImageUploader from 'quill-image-uploader';

// Register the ResizeModule with Quill
Quill.register("modules/resize", ResizeModule);
Quill.register('modules/imageUploader', ImageUploader);

export const ReactQuillWrapper = () => {
  const [text, setText] = useState("");

  const handleChange = (value) => {
    setText(value);
  };

  return (
    <div>
      <ReactQuill 
        value={text}
        modules={modules} // Apply the custom modules configuration
        formats={formats}
        onChange={handleChange}
      />
    </div>
  );
};
