import React, { createContext, useState } from "react";

export const PopupContext = createContext();

export const LoginPopupProvider = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState("Home");
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [isloading, setLoading] = useState(false);

  const clearMenu = () => setSelectedMenu("Home");
  const setCurrentMenu = (id) => setSelectedMenu(id);
  const setLogIn = () => setIsLogedIn(true);
  const setLogOut = () => setIsLogedIn(false);
  const setUnauthorized = () => {
    console.log("asdfsafdsfdsfdsfdsfdsfsfs");
    
    setIsLogedIn(false);
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  };
  const setLoadingStart = () => setLoading(true);
  const setLoadingStop = () => setLoading(false);

  return (
    <PopupContext.Provider
      value={{
        selectedMenu,
        clearMenu,
        setCurrentMenu,
        isLogedIn,
        setLogIn,
        setLogOut,
        isloading,
        setLoadingStart,
        setLoadingStop,
        setUnauthorized
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};
