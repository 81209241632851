import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PopupContext } from "../LoginPopupContext";

function DeleteAllRecordsDialog(props) {
  const { setLoadingStart, setLoadingStop,setUnauthorized } = useContext(PopupContext);

  const [error, setError] = useState("");
  const [school, setSchool] = useState(props.nameSchool);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);


  useEffect(() => {
    setSchool(props.nameSchool)
  }, [props.nameSchool]);

  const handleEdit = () => {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
      props.onHide();
    }, 2000);
  };

  const handleUpdate = () => {
    setLoadingStart();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const raw = JSON.stringify({
        nameSchool : `${props.nameSchool}`
      });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/deleteUserBySchoolName`, requestOptions)
    .then(async (response) => {
      if (response.status === 200) {
        handleEdit();
      }else if (response.status === 401) {
        setUnauthorized();
      }
      setLoadingStop();
    })
    .catch((error) => {
      setLoadingStop();
      setErrors(error);
      console.log(error);
    });
  }

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Students data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form> 
            <Row className="mb-3">
              {isSuccess ? <div style={{ background: "#99db9945", padding: "5px", border: "solid 1px #008000de", borderRadius: "5px"}}>
                <span>Successfully record Deteletd.</span>
              </div>:''}
              <Col>
                <Form.Group controlId="surname.ControlInput1">
                  <Form.Label>Are you sure you want to delete {props.show ? school:''} school All students data?</Form.Label>
                </Form.Group>
              </Col>

            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="danger" onClick={handleUpdate}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteAllRecordsDialog;
