const handleImageUpload = async (file, success, failure) => {
  try {    
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const formData = new FormData();
    formData.append("file", file);

    // Replace with your image upload endpoint
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/test/uploadImg`, {
      headers: myHeaders,
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Image upload failed");
    }

    const data = await response.json();
    
    // Return the image URL to be inserted into the editor
    return data.url;
  } catch (error) {
    console.error("Image upload failed:", error);
    throw new Error("Image upload failed");
  }
};

export const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["image"],
    ["clean"],
  ],
  imageUploader: {
    upload: handleImageUpload,
  },
  resize: {
    modules: ["Resize", "DisplaySize", "Toolbar"], // Add resize-specific settings
  },
};

export const formats = [
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "image",
];

export const noImagemodules = {
  toolbar: [["bold", "italic", "underline"]],
};

export const noImageformats = ["bold", "italic", "underline"];
