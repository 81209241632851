import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../component/LoginPopupContext";
import Loader from "./Loader";


export default function LoginPage() {
  const { setLogIn, setCurrentMenu, setLogOut, isloading, setLoadingStart, setLoadingStop } = useContext(PopupContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const initialized = useRef(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, [showPassword]);

  const navigate = useNavigate();

  const handleLoginAPI = () => {
    setLoadingStart(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      userName: username,
      password: password
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`, requestOptions)
      .then(async (response) => {
        if (response.status === 200) {
          let paresData = await response.json();
          sessionStorage.setItem("token", paresData.token);
          sessionStorage.setItem("userinfo", JSON.stringify(paresData.user));
          sessionStorage.setItem("userId", paresData.user._id);
          sessionStorage.setItem("loggedIn", true);
          setLogIn();
          if (paresData.user.roleType === "Admin") {
            navigate("/adminDashboard");
          } else if (paresData.user.roleType === "Student") {
            navigate("/studentDashboard");
          } else if (paresData.user.roleType === "TestManager") {
            setCurrentMenu("testList");
            navigate("/testDashboard");
          }
        } else {
          let paresData = await response.json();
          setErrors(paresData.message);
        }
        setLoadingStop();
      })
      .catch((error) => {
        setLoadingStop();
        setErrors(error);
        console.log(error);
      });
  };

  const setErrors = (error) => {
    setIsError(true);
    setError(error);
    setTimeout(() => {
      setIsError(false);
      setError("");
    }, 2000);
  };

  const handleLogin = () => {
    if (!username || !password) {
      setErrors("Please enter username password.");
    } else {
      handleLoginAPI();
    }
  };

  return (
    <>
      <div
        className={`alert alert-danger fade ${isError ? "show" : " "}`}
        role="alert"
      >
        <strong>{error}</strong>
      </div>

      <style>{`body {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: sans-serif;
          line-height: 1.5;
          min-height: 100vh;
          background: #333;
          flex-direction: column;
          margin: 0;
      }

      .mainn {
          background-color: #fff;
          border-radius: 15px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          padding: 10px 20px;
          transition: transform 0.2s;
          width: 500px;
          text-align: center;
      }

      h1 {
          color: #02a9a9;
      }

      label {
          display: block;
          width: 100%;
          margin-top: 10px;
          margin-bottom: 5px;
          text-align: left;
          color: #555;
          font-weight: bold;
      }


      input {
          display: block;
          width: 100%;
          margin-bottom: 15px;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #ddd;
          border-radius: 5px;
      }

      button {
          padding: 15px;
          border-radius: 10px;
          margin-top: 15px;
          margin-bottom: 15px;
          border: none;
          color: white;
          cursor: pointer;
          background-color: #02a9a9;
          width: 100%;
          font-size: 16px;
      }

      .wrap {
          display: flex;
          justify-content: center;
          align-items: center;
      }`}</style>
      <div className="App">

        <img
          src="/logo512.jpg"
          className="background-video"
        />

        <div className="mainn">
          <img
          style={{marginTop:"15px"}}
            src="/logo.jpg"
            height={'60px'}
            width={'215px'}
          />
          <h1 style={{marginTop:"15px"}}><strong>Login</strong></h1>
          <label for="first">Username:</label>
          <input
            type="text"
            id="first"
            name="first"
            placeholder="Enter your Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label for="password">Password:</label>
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Enter your Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#555",
                userSelect: "none"
              }}
            >
              {showPassword ? "Hide" : "Show"}
            </span>
          </div>

          <div className="wrap">
            <button type="submit" onClick={handleLogin}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
